.buyerguide-fullbanner {

    @media screen and (max-width: 767px) {

        .fullbanner img {
            height: auto;
            object-fit: scale-down;
            min-height: auto;
        }

    }
}
.bottom_nav {
  overflow-x: auto;
  position: relative;
  z-index: 6;

  ul {
    padding: 0.8rem 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    @media only screen and (max-width: 820px) {
      min-width: fit-content;
      gap: 0.5rem;
      justify-content: center;
    }

    li {
      margin: 0 0.5rem;

      a {
        text-decoration: none;
        color: #000;
        padding: 0 0.5rem;

        @media only screen and (max-width: 700px) {
          padding: 0 0;
          font-size: 13px;
      }

      }
    }

    @media only screen and (max-width: 700px) {
      li {
        min-width: fit-content;
      }
    }
  }

  hr {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  }
}

.sticky_overview_nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 25px 40px !important;
  z-index: 20;

  @media only screen and (max-width: 1024px) {
    top: 93px !important;
    padding: 0px 0px !important;
    background: #ffffff;
    border-top: 1px solid #cecccc;
    border-bottom: 1px solid;
    width: 100% !important;
}


  hr {
    display: none;
  }
}
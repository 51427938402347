.grouppage {
    background-color: #A0AAB2;
    padding: 5rem 0 0;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 700px) {
        padding: 4rem 1rem 4rem;
        
    }


    .main_group {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;

        p {
            line-height: 1.4;
            margin-bottom: 1rem;
            text-align: justify;
        }

        .title_underline {
            position: relative;
            margin-bottom: 10px;
        }

        .title_underline::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 2px;
            width: 32px;
            background-color: #005f9e;

        }
    }

    .listing_group {

        display: block;
        

        ul {
            list-style: none;
            margin-top: 3rem;
            display: flex;
            justify-content: space-between;


            li {
                flex: 1;
                text-align: center;
                position: relative;
                border-right: 1px solid;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    right: 0;
                    background-color: #121212;
                    visibility: hidden;
                    transform: scaleX(0);
                    transition: all 0.4s ease-in-out;
                }

                &:hover::after {
                    visibility: visible;
                    transform: scaleX(1);
                }

                &:hover small {
                    color: #ffff;
                    z-index: 2;
                    position: relative;
                }

                &:hover .hiddenimg {
                    opacity: 1;
                    transform: translateY(0%);
                }


                h4 {
                    font-size: 2.625rem;
                    text-transform: uppercase;
                    font-family: "TFArrow Light";
                    font-weight: 300;
                    padding: 3.125rem 0;
                    position: relative;
                    transition: 1s all ease;


                    @media only screen and (max-width: 768px) {
                        font-size: 1.8rem;
                    }


                    small {
                        transition: 1s all ease;
                        color: #000000;
                    }

                }

                .hiddenimg {
                    transform: translateY(10%);
                    pointer-events: none;
                    opacity: 0;
                    z-index: 2;
                    padding: 2rem 0 5rem;
                    position: relative;
                    transition: all 0.4s ease-in-out;
                }


            }



            li:last-child {
                border: none;
            }
        }

    }

    .mobileslideronly {
        display: none;

        @media only screen and (max-width: 700px) {
            .slider_cntent {
                margin-top: 1rem;
            }
            .a-abtmob{
                color: black;
            }
        }
    }




    @media only screen and (max-width: 700px) {

        .mobileslideronly {
            display: block;
        }

        .listing_group {
            display: none;
        }

    }



}


.barcode-sec {

	padding: 35px 0px 35px;
	border-bottom: 1px solid #eee;
	margin-bottom: 0;

	.pdp_decription {
		display: flex;
		align-items: center;
		justify-content: center;

		@media only screen and (max-width: 700px) {

			flex-direction: column;
		}


		.bannertagline {
			width: 70%;

			@media only screen and (max-width: 700px) {
				width: 100%;
			}

		}

		.pdp_barcode {
			display: flex;
			align-items: center;
			width: 30%;

			@media only screen and (max-width: 1024px) {

				width: 100%;
			}

		}

		.barcode {
			display: flex;
			align-items: center;
			flex-direction: column;
			flex: 1;

			@media only screen and (max-width: 1024px) {
				margin-top: 1rem;
				width: 100%;
			}


			img {
				max-width: 85px;
			}

			span {
				padding: 0 0.5rem;
				font-size: 14px;
				font-weight: 600;
				padding-top:10px;

				@media only screen and (max-width: 390px) {
					font-size: 13px;
				}


			}

		}

	}

	.btn-viewcenter {
		text-align: center;
		margin-top: 3rem;
		clear: both;
		display: block;
	}

	.btn-viewcenter a {
		background: #000;
		padding: 10px 30px;
		color: #fff;
		font-size: 14px;
		line-height: 1;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		outline: 0 !important;
		margin: 10px 10px;


		@media only screen and (max-width: 700px) {

			display: block;

		}
	}


}
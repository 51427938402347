header {
  position: absolute;
  z-index: 5;
  width: 100%;
  padding: 24px 0;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav_logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 75px;
        height: 75px;

        @media only screen and (max-width: 700px) {
          width: 75px;
          height: 75px;
        }
      }
    }


  }


  .nav_links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: #fff;

    .nav_btn {

      a {
        color: #ffffff;
      }

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    img {
      cursor: pointer;
    }
  }

  .sticky_nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px 40px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;


    .nav_btn a {
      color: #000000;
    }

  }

}

.side_bar_shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9;
}

.side_bar_shadow_active {
  width: 100%;
  overflow: hidden;
}

.side_bar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 26%;
  overflow: hidden;
  height: 100vh;
  z-index: 10;
  background-image: url('../../../assets/nav-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.5s ease;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 90%;
    top: 100%;
    right: 0;
  }

  .side_bar_container {
    color: #fff;
    padding: 2rem 1.5rem;

    .side_bar_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        cursor: pointer;
      }

      p {
        font-size: 18px;
      }
    }

    .option_container {
      margin-top: 40%;

      @media only screen and (max-width: 700px) {
        margin-top: 10%;
      }

      .option {
        margin-top: 2rem;

        select {
          width: 100%;
          padding: 10px 0;
          border: none;
          outline: none;
          background: transparent;
          color: #fff;
          font-family: 'Optima', sans-serif;
          font-size: 16px;
          text-transform: uppercase;
          border-bottom: 1px solid #656565;
          cursor: pointer;

          option {
            background: transparent;
            color: #000;
            padding: 10px;
            cursor: pointer;
          }
        }
      }
    }


    .btnbottom {
      position: absolute;
      width: 100%;
      bottom: 5%;
      left: 0;
      right: 0;
      z-index: 2;
      padding: 0 1.5rem;

      @media only screen and (max-width: 1280px) {
        bottom: 15vh;
      }


      .btn_primary {
        width: 100%;

      }

    }

  }
}

.sidebar_active {
  right: 0;
  transition: all 0.5s ease;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 90%;
    top: 20%;
    right: 0;
  }
}

.bottom_navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: none;

  @media only screen and (max-width: 700px) {
    display: block;
  }

  .bottom_nav_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 52%;


    .btn {
      display: flex;
      align-items: center;
      text-align: center;
      background-color: #005f9e;
      color: #fff;
      height: 60px;
      padding: 0 1rem;
    }

    div {
      display: flex;
      align-items: center;
      text-align: center;
      height: 60px;
      padding: 0 1rem;

    &:nth-child(2)
      img {
        width: 27px;
        height: 27px;
      }

    }
  }
}

#kenytChatBubble.style2 {
  bottom: 0px !important;
  right: 16px !important;
}
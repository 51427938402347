.jointeam {
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.40);
    padding-top: 2rem;



    .exp_item {
        position: relative;

        img {
            object-fit: cover;
            width: 100%;
            max-height: 700px;
            min-height: 700px;

            @media only screen and (max-width: 700px) {

                max-height: 170px;



            }
        }
    }



    .mang_title_container {
        text-align: center;
        max-width: 550px;
        margin: 0 auto;
        padding-top: 9.063rem;
        padding-bottom: 3rem;

        @media only screen and (max-width: 700px) {
            padding-top: 4rem;
            padding-bottom: 3rem;
            text-align: left;
            padding-left: 16px;
            padding-right: 16px;
        }

    }



}
.admin_layout {
  display: flex;

  .aside_layout {
    position: fixed;
    z-index: 5;
    width: 298px;
    height: 100vh;
    background-color: #121212;
    color: #fff;
    overflow-y: scroll;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main_content_layout {
    position: relative;
    z-index: 2;
    left: 298px;
    width: calc(100% - 298px);
  }
}

.valuepage {
    width: 100%;
    background-image: url('../../../../assets/pdp/info.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 5rem 0rem;

    .value_center {
        text-align: center;
        margin: 0 auto;
        width: 65%;

        .value_title {
            &>p {
                position: relative;
                margin-bottom: 1.5rem;
                font-size: 41px;
            }

            &>p:before {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 2px;
                width: 40px;
                background-color: #005f9e;
            }
            
            h2 {
                font-size: 21px;
                margin-bottom: 0.5rem;
            }
        }

        @media only screen and (max-width: 700px) {
            width: 100%;
        }
    }

    .value-number {
        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 2rem;
            margin-top: 2rem;

            @media only screen and (max-width: 700px) {
                flex-direction: column;
            }

            li {
                margin-top: 1rem;
                position: relative;
                flex: 1;

            p{text-align: justify;}    

                @media only screen and (max-width: 1180px) {
                    flex: calc(50% - 2rem);
                }

                @media only screen and (max-width: 767px) {
                    flex: calc(100% - 0rem);
                }


                span {
                    font-size: 5.875rem;
                    opacity: 0.1;
                    font-family: "TFArrow Light";

                    @media only screen and (max-width: 700px) {
                        position: absolute;
                        top: -30px;
                    }
                }

                h3 {
                    font-family: "TFArrow Medium";
                    text-transform: inherit;
                    margin-bottom: 1.2rem;
                    min-height: 5rem;
                    line-height: 1.2;
                    font-size: 23px;


                    @media only screen and (max-width: 700px) {
                        font-size: 18px;
                        letter-spacing: 0.36px;
                        min-height: auto;
                    }
                }

            }

        }
    }
}
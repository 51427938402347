.pdp_features {
	background-color: #000;
	padding: 4rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #fff;



	.features_title_container {
		width: 40%;
		text-align: center;

		@media (max-width: 700px) {
			width: 100%;
			padding: 0 1rem;
		}

		&>p {
			position: relative;
			margin-bottom: 1.5rem;
		}

		&>p:before {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			transform: translateX(-50%);
			height: 2px;
			width: 40px;
			background-color: #005f9e;
		}



	}

	.features_items {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		margin-top: 3rem;

		@media (max-width: 700px) {
			grid-template-columns: repeat(3, 1fr);
		}

		.features_item {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 180px;
			height: 180px;
			padding: 2rem;
			text-align: center;
			border-bottom: 1px solid rgb(255 255 255 / 50%);
			border-right: 1px solid rgb(255 255 255 / 50%);

			@media (max-width: 1180px) {
				width: 125px;
				height: 125px;
				padding: 1rem;
			}

			@media (max-width: 700px) {
				width: 100%;
				padding: 0rem 1rem;

				&:nth-child(3n+3) {
					border-right: none;
				}

			}


			.bullet {
				width: 20px;
				height: 20px;
				background-color: rgb(255, 255, 255);
				margin-bottom: 1rem;

			}
		}

		.features_item:last-child {
			border-bottom: none;
		}
	}
}

.pdp_features .features_items .features_item:nth-child(6n+6) {
	border-right: none;
}

.pdp_features .features_items .features_item:nth-last-child(-n + 6) {
	border-bottom: none;
}

@media (max-width: 700px) {


	.pdp_features .features_items .features_item:nth-last-child(-n+6) {
		border-bottom: 1px solid #ccc;
	}

	.pdp_features .features_items .features_item:nth-last-child(-n+3) {
		border-bottom: 0;
	}


}
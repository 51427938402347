.emi_calculator {
  width: 100%;
  background: url('../../../../assets/emibg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 6.25rem;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;


  .loancheck {
    padding-bottom: 2rem;
    padding-left: 16px;
    padding-right: 16px;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;

    h2{font-size: 41px;}

    .text_center {
      text-align: center;
    }
  }

  .calc_container {
    width: 1130px;
    height: 562px;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    height: auto;

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 700px) {
      flex-direction: column;
      width: 100%;
    }


    &>div {
      width: 50%;

      @media (max-width: 700px) {
        width: 100%;

      }


      input {
        display: block;
        z-index: 3;
        position: relative;
      }
    }

    &>div:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.4);
    }

    input[type='text'] {
      font-family: 'Optima', sans-serif;
      margin: 1rem 0;
      float: right;
      padding: 12px 18px;
      border: 0.5px solid rgba(0, 0, 0, 0.4);
      background: rgba(255, 255, 255, 0.4);
      outline: none;
    }

    .calc_wrapper {
      padding: 40px;

      .single_container {
        margin-bottom: 45px;

        // border: 1px solid #000;
        &>input[type='text'] {
          width: 164px;
        }

        .btn_group {
          position: relative;
          z-index: 3;
          display: flex;
          align-items: center;
          // margin: 1rem 0;
          text-align: center;
          float: right;

          input {
            padding: 11px 18px;
            width: 65px;
          }

          button {
            font-family: 'Optima', sans-serif;
            width: 50px;
            padding: 12px 18px;
            border: 0.5px solid rgba(0, 0, 0, 0.4);
            background: rgba(255, 255, 255, 0.4);
            cursor: pointer;
          }
        }
      }

      .submit_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn_primary {
          color: #000;
          border-color: #000;
          width: 75%;
          @media (max-width: 700px) {
            width: 60%;
          }
        }

        .btn_primary:last-child {
          width: 20%;

          @media (max-width: 700px) {
            width: 35%;
          }

        }
      }
    }
  }

  .tot_container {
    &>div {
      text-align: right;
      padding: 45px;

      @media (max-width: 700px) {
        text-align: center;
      }


      .alart {
        margin-top: 4rem;
        text-align: left;
      }
    }

    &>div:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

    .main_price {
      font-size: 56px;
      font-weight: 700;

      @media (max-width: 700px) {
        font-size: 32px;
      }


    }

    .other_price {
      font-size: 32px;
    }
  }

  @media (max-width: 700px) {
    .layout {
      padding: 0;
    }
  }


}

.activeTime {
  background-color: #005f9e !important;
  color: #fff;
}

// range slider
.range_container {
  width: 100%;
  // margin: 24% auto;
  position: relative;

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    outline: none;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #000;
    cursor: pointer;
    z-index: 3;
    position: relative;
    // background-color: black;
  }

  .progress_bar {
    width: 0%;
    height: 7px;
    background-color: red;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.h6,
h6 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-weight: 500;

}

.text-center {
    text-align: center;
}

.listing-page {
    padding-top: 120px;
}

.psudo-box {
    position: relative;

    .psudo-border:before {
        position: absolute;
        content: '';
        background: #005F9E;
        width: 42px;
        height: 2px;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .title-center {
        font-size: 50px;
    }

    .flex-box {
        flex-direction: column;
    }

    .bottom-tagline h3 {
        font-size: 22px;
    }
}
//  .new-htwo-hone{
//     font-family: TFArrow Light, sans-serif;
//     font-size: 56px;
//     font-style: normal;
//     font-weight: 300;
//     line-height: 1.2;
//     text-transform: uppercase;
//     letter-spacing: normal;
// }
.hero {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;

	.homebanner {
		position: absolute;
		bottom: 0;
	}


	@media only screen and (max-width: 700px) {
		height: 90vh;

		overflow: hidden;
	}

	.hero_container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		#hero_video {
			position: absolute;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;

			@media only screen and (max-width: 400px) {}

			@media only screen and (max-width: 380px) {
				height: 100vh;
			}

			@media (max-width: 500px) {
				object-fit: cover;
				position: relative !important;
			}


		}

		.video_filter {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.hero_content {
			position: absolute;
			width: 65%;

			@media only screen and (max-width: 1024px) {
				width: 100%;
			}

			.hero_sub_title {
				position: relative;
				margin-bottom: 1rem;
			}

			.hero_sub_title::after {
				content: "";
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				height: 1px;
				width: 32px;
				background-color: #005f9e;
			}

			h1 {
				margin: 24px 0;
			}

			.detail {
				margin: 24px 0;
				word-wrap: break-word;


			}

			.detail-new {
				margin: 24px 0;
				word-wrap: break-word;
				font-family: "Optima", sans-serif;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.48px;
				text-transform: uppercase;
				line-height: 1.2;

			}

			p {
				margin-bottom: 24px;

				@media only screen and (max-width: 700px) {
					margin: 2rem 0;
				}
			}
		}
	}
}

.hero_content>h1:first-child {
	font-size: 92px;
}
@media only screen and (max-width: 700px) {

	
.hero_content > h1:first-child {
    font-size: 48px;
}

}

.counting {
    padding: 84px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1200px) {
        padding: 2rem;
        flex-direction: column;
    }

    @media only screen and (max-width: 700px) {
        padding: 4rem 0;
    }

    .left_container {
        width: 40%;

        .btn_primary {
            color: #000000;
            border-color: #000000;
            margin-top: 3rem;

        }

        @media only screen and (max-width: 1200px) {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        @media only screen and (max-width: 700px) {
            align-items: flex-start;
        }

        .title_underline {
            position: relative;

            @media only screen and (max-width: 1200px) {
                // width: 70%;
                margin-top: 0.5rem;
            }

            @media only screen and (max-width: 700px) {
                width: 70%;
                margin-top: 0.5rem;
            }
        }

        .title_underline::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            height: 1px;
            width: 32px;
            background-color: #005f9e;

            @media only screen and (max-width: 1200px) {
                left: 50%;
                transform: translateX(-50%);
            }

            @media only screen and (max-width: 700px) {
                left: 0;
                transform: translateX(0);
            }
        }
    }

    .right_container {
        display: flex;
        align-items: center;
        justify-content: center;
       
        @media only screen and (max-width: 1200px) {
            margin: 3rem 1rem 2rem;
            width: 100%;
            flex-wrap: wrap;

        }

        &>div {
            min-width: 175px;
            padding: 50px 50px;
            border: 0.5px solid rgba(0, 0, 0, 0.40);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
           



            @media only screen and (max-width: 1000px) {
                height: 149px;
                min-width: auto;
                max-width: calc(33.33% - 3px) !important;
                margin-right: 3px !important;
            }

            h2 {
                font-size: 48px;
                font-weight: 700;
                color: #005F9E;

                @media only screen and (max-width: 700px) {
                    font-size: 30px;
                }
            }

            p {
                font-size: 16px;
                text-transform: uppercase;

                @media only screen and (max-width: 700px) {
                    font-size: 12px;
                }
            }
        }
    }
}

.counting.countingtransfomr-screen .right_container>div {
    transform: skewY(20deg);
    transition: transform 0.9s ease-out;
}

.counting .right_container>div {
    margin-right: 10px;
     transform: skewY(30deg);
    transition: transform 0.9s ease-out;

}
.counting .right_container > div:last-child h2:after {
    position: absolute;
    content: '+';
    right: auto;
}
.contactus {
    width: 100%;
    background: url("../../../../assets/ctbg.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgb(0 0 0 / 80%);


    .contact_container {
        .addres-listing {
            ul {
                list-style: none;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-around;
                gap: 3rem;

                @media only screen and (max-width: 767px) {
                    align-items: baseline;
                    flex-direction: column;
                }

                li {
                    flex-basis: 30.33%;
                    padding-left: 2rem;


                    h6 {
                        font-size: 24px;
                        position: relative;

                        span {
                            position: absolute;
                            left: -2rem;
                        }

                    }
                }

            }
        }



        &>div {
            padding-top: 6.25rem;
            padding-bottom: 5rem;

            &>p {
                font-size: 24px;
                color: #fff;
                line-height: 140%;

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            @media only screen and (max-width: 767px) {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }

        .bg-about {
            background-color: #fff;
            padding: 78px 80px;

            @media only screen and (max-width: 767px) {
                padding: 42px 24px;
            }


            .title_container {
                padding: 0 0 2rem 0;
            }

            .input_container {
                width: 100%;



                .input_wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 1rem;

                    @media only screen and (max-width: 1200px) {
                        flex-direction: column;
                    }

                    div {
                        width: 100%;
                        margin-bottom: 1rem;

                
                        input {
                            width: 100%;
                            height: 42px;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #000;
                        }
                        select {
                            width: 100%;
                            height: 42px;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #000;
                        }

                        input::placeholder {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #000;
                        }
                    }

                    .flex-num {
                        display: flex;

                        .col-md-3 {
                            flex-basis: 10%;
                            @media only screen and (max-width: 700px) {
                                flex-basis: 20%;

                            }
                        }
                        .col-md-9 {
                            flex-basis: 90%;
                            padding-left: 20px;


                        }

                    }
                    

                }


                input.am-button {
                    border-color: #000;
                    border: 1px solid !important;
                    max-width: 200px;
                    margin: 0 auto;
                    display: block;
                }


                .flexrow {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: 1rem;

                    &>div {
                        width: 50%;
                    }

                    &>div:nth-child(2) {
                        text-align: right;
                    }

                    @media only screen and (max-width: 767px) {
                        margin-top: 0;
                    }

                    .authorized {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        span {
                            font-size: 11px;
                            margin-left: 0.5rem;
                        }
                    }

                    @media only screen and (max-width: 700px) {
                        &>div {
                            width: 100%;
                        }

                        .btn-right {
                            width: 100%;
                            margin-top: 1rem;
                        }
                    }


                    .btn_primary {
                        color: #000;
                        border-color: #000;

                        @media only screen and (max-width: 700px) {
                            width: 100%;
                        }
                    }
                }


            }
        }
    }
}

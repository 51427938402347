.admin_navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 1rem 2rem;
  border-bottom: 0.5px solid rgba(96, 96, 96, 0.4);
  .right_child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    .search_bar {
      display: flex;
      align-items: center;
      background: #d8d8d8;
      width: 320px;
      height: 42px;
      padding: 5px 10px;
      border-radius: 20px;
      input {
        border: none;
        outline: none;
        background-color: transparent;
        // border: 1px solid red;
        width: 100%;
        font-family: 'TFArrow Medium';
        margin-left: 5px;
        font-size: 16px;
      }
    }
    .user_profile {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 5px;
      p {
        font-size: 16px;
      }
      .user_image {
        width: 42px;
        height: 42px;
        box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
      }
    }
  }
}

.project {
  margin-top: 8rem;
  @media only screen and (max-width: 700px) {
    margin-top: 4rem;
  }
  .project_title_container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .project_title {
      width: 50%;
      @media only screen and (max-width: 700px) {
        width: 100%;
        margin: 0 1rem;
      }
      .title_underline {
        position: relative;
        margin-bottom: 2rem;
        font-size: 41px;
        @media only screen and (max-width: 700px) {
					font-size: 32px;
				}
      }
      .title_underline::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 40px;
        background-color: #005f9e;
      }
      h2{font-size: 21px;margin-bottom: 0.5rem;margin-top: 1rem;}
    }
  }
  .project_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-top: 1rem;
    border-bottom: 1px solid #bbbbbb;
    div {
      display: flex;
      gap: 2rem;
      h3 {
        font-size: 24px;
        color: #bbbbbb;
        font-weight: 400;
        cursor: pointer;
        @media only screen and (max-width: 700px) {
          font-size: 16px;
        }
      }
      .menu_active {
        position: relative;
        color: #000;
      }
      .menu_active::after {
        content: '';
        position: absolute;
        bottom: -1.05rem;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 100%;
        background-color: #005f9e;
      }
    }
  }
  .project_slider {
    width: 100%;
    position: relative;
    margin-top: 2rem;
    padding-left: 2rem;
    @media only screen and (max-width: 700px) {
      padding: 0 1rem;
    }
    .project_item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .project_item_img_con {
        width: 100%;
        height: 686px;
        overflow: hidden;
        @media only screen and (max-width: 700px) {
          width: 100%;
          height: 500px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s ease;
        }
      }

      .project_item_img_con:hover img {
        transform: scale(1.2);
      }
      .shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.26);
      }
      .slider_cntent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        color: #fff;
        transition: all 0.5s ease-in;

        h2 {
          letter-spacing: 3px;
      }

        .btn_primary {
          margin-top: 2rem;
        }
      }
    }
    .slider_btn {
      position: absolute;
      // top: 50%;
      z-index: 3;
      // transform: translateY(-50%);
      // cursor: pointer;
      // width: 50%;
      // height: 100%;
      width: 65px;
      height: 65px;
      top: 405px;
      // top: 0;
      margin: 0;
      // transition: cursor 0.3s;
      border: 1px solid #ccc;
      border-radius: 100%;
      cursor: pointer;


      &::after {
        display: none;
      }
      @media only screen and (max-width: 700px) {
        display: none;
      }
    }
    .slider_btn_left {
      left: 32px;
      // cursor:
      //   url(../../../../assets/left_slider.png) 32 32,
      //   auto;
      // background: url(''../../../../assets/left_slider.png) no-repeat;
      background: url(../../../../assets/left_slider.png) no-repeat;
    }
    .slider_btn_right {
      right: 40px;
      // cursor:
      //   url(../../../../assets/right_slider.png) 32 32,
      //   auto;
      background: url(../../../../assets/right_slider.png) no-repeat;
    }
  }
}

.ethospage {
    width: 100%;
    background-image: url('../../../../assets/about/ethosbg.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    padding: 5rem 0rem;
    background-color: black;
    color: #ffffff;

    @media screen and (max-width:1024px) {
        background-position: top center;
    }


    .ethos_row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
        }




        .left_container {
            width: 45%;

            @media screen and (max-width: 1024px) {
                .hide-mobile {
                    display: none;
                }
            }

            @media screen and (max-width: 1024px) {
                width: 100%;
            }

            p {
                line-height: 1.4;
                margin-bottom: 1rem;
                text-align: justify;
            }

            .detailpara-ethos>p:first-child {
                margin-top: 3rem;
                // font-weight: 700;
            }

            .title_underline {
                position: relative;
                margin-bottom: 10px;
                text-transform: uppercase;

            }

            .title_underline::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 2px;
                width: 32px;
                background-color: #005f9e;

                @media only screen and (max-width: 700px) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .right_container {
            width: 50%;
            text-align: center;

            & .only-mobile {
                display: none;

                .title_underline,
                h2 {
                    text-transform: uppercase;
                }
            }

            @media only screen and (max-width: 1024px) {

                .only-mobile {
                    display: block;
                    margin-bottom: 3rem;
                }

            }

            @media only screen and (max-width: 700px) {
                width: 100%;
                margin-bottom: 2rem;
                // padding-left: 1rem;
            }
        }



    }
}
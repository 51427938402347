.gallerycollapseluma {
	margin: 4rem 0;

	.gallary_title {
		width: 550px;
		text-align: center;
		margin: 8.688rem auto 3rem;
		max-width: 100%;


		@media (max-width: 700px) {
			width: 100%;
		}

		.psudo-border {

			&>p {
				position: relative;
				margin-bottom: 1.5rem;
				font-size: 20px;
			}

			&>p:before {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 0;
				right: 0;
				margin: 0 auto;
				height: 2px;
				width: 40px;
				background-color: #005f9e;
			}
		}
	}

	.gallary_containerluma {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);


		@media only screen and (max-width: 700px) {
			gap: 0.5rem;
			margin-top: 0.5rem;
		}

		.gallary_item {
			position: relative;
			overflow: hidden;
			cursor: pointer;

			&img {
				transition: 0.3s ease-out;
			}


			.overlay {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				padding: 15px;
				text-align: center;
				color: #fff;
				background: rgb(0 0 0 / 50%);
				opacity: 0;
				transition: 0.3s ease-out;
				display: flex;
				align-items: center;
				justify-content: center;

			}

			&:hover .overlay {
				opacity: 1;
			}



			.hover-text {
				transform: translateY(30px);
				opacity: 0;
				transition: all .3s ease-out;
			}

			&:hover .overlay .hover-text {
				opacity: 1;
				transform: translateY(0);
			}


		}

		@media only screen and (min-width: 700px) {
			grid-template-columns: repeat(4, 1fr);
			gap: 1rem;
			margin-top: 1rem;

			.grid_col_span_2 {
				grid-column: span 2;
			}

			.grid_row_span_2 {
				grid-row: span 2;
			}
		}

		.gallary_item {
			@media only screen and (max-width: 700px) {
				max-height: 130px;
				min-height: 130px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'TFArrow Medium';
  src: url('./assets/fonts/TFArrow/TFArrow-Medium.ttf');
}

@font-face {
  font-family: 'TFArrow Bold';
  src: url('./assets/fonts/TFArrow/TFArrow\ Bold.ttf');
}

@font-face {
  font-family: 'TFArrow Light';
  src: url('./assets/fonts/TFArrow/TFArrowLight.ttf');
}

@font-face {
  font-family: 'Optima Bold';
  src: url('./assets/fonts/optima-cufonfonts/Optima\ Bold.TTF');
}

@font-face {
  font-family: 'Italic';
  src: url('./assets/fonts/optima-cufonfonts/Optima\ Italic.ttf');
}

@font-face {
  font-family: 'Optima Medium';
  src: url('./assets/fonts/optima-cufonfonts/Optima\ Medium.ttf');
}

@font-face {
  font-family: 'Optima';
  src: url('./assets/fonts/optima-cufonfonts/Optima.TTF');
}

body {
  font-family: 'Optima', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.5s ease;
  overflow-x: hidden;
}

.btn_primary {
  font-family: 'Optima', sans-serif;
  width: 228px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100px!important;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  letter-spacing: 0.64px;
}

.btn_primary:hover {
  background: rgba(255, 255, 255, 0.4);
  transform: translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

html.with-fancybox body.hide-scrollbar {
  overflow: visible !important; 
  margin-right: 0 !important;
}

h1 {
  font-family: 'TFArrow Light', sans-serif;
  font-size: 94px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 8.9px;

  @media only screen and (max-width: 700px) {
    font-size: 48px;
    letter-spacing: 0.96px;
  }
}

h2 {
  font-family: 'TFArrow Light', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  text-transform: uppercase;

  @media only screen and (max-width: 1200px) {
    font-size: 42px;
  }

  @media (max-width: 700px) {
    font-size: 32px;
  }
}

h3 {
  font-family: 'Optima', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  line-height: 1.2;

  @media only screen and (max-width: 700px) {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
  }
}

h5 {
  font-family: 'TFArrow Medium', sans-serif;
}

img {
  max-width: 100%;
}

p {
  font-family: 'Optima', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  @media only screen and (max-width: 700px) {
    font-size: 12px;
    line-height: 140%;
  }
}

.overlay-bg {
  position: relative;
}

.overlay-bg:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5) 50% / cover no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

input {
  font-family: 'Optima', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

input::placeholder {
  color: #000;
}



.tabletext .table-responsive {
  margin-top: 15px;
}


.galleryrow .card a {
  color: #fff;
  text-decoration: none;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}
a:-webkit-any-link:focus-visible {
    outline-offset: 0px;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

button:active {
  outline: none;
  }
  
  button:focus {outline:0;}

  .fancybox__backdrop {
    background: rgb(24 24 27) !important;
}
select {
  background: transparent;
}

.new-hone{
  font-family: "TFArrow Light", sans-serif;
  font-size: 94px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 8.9px;
  display: flex;
  flex-direction: column;
  gap: 0;


  @media only screen and (max-width: 700px)  {
   
        font-size: 48px;
        letter-spacing: 0.96px;
    }
}

span.new-hone-hthree{
  font-family: Optima,sans-serif;
  margin-bottom: 1.5rem;
  letter-spacing: 3.2px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  @media only screen and (max-width: 700px) {
                         
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
}


}

.new-hone-hthree{
  font-family: Optima,sans-serif;
  margin-bottom: 1.5rem;
  letter-spacing: 3.2px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  @media only screen and (max-width: 700px) {
                         
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
}


}

h2.titlepara{
 
      font-family: Optima, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;
text-transform: none;
letter-spacing: normal;

@media only screen and (max-width: 700px) {
  
      font-size: 12px;
      line-height: 140%;
  }


}



.new-htwo-hone{
  font-family: TFArrow Light, sans-serif;
  font-size: 21px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: normal;


  @media only screen and (max-width: 700px) {
    
        margin-bottom: 1.5rem;
        font-size: 24px;
    }

}


.margin-none{
  margin-bottom: 0 !important ;
}

.margin-one{
  margin-bottom: 1rem !important;
}

.margin-two{
  margin-bottom: 2rem !important;
  text-align: left;
}

.letter-space{
  letter-spacing: 1.4px !important ;
}
.new-sizecustom{font-size: 41px;}


.info_title, #Amenities, #Gallery, .features_title_container, #Project, #Plan, #Virtual, #Walkthrough, #View{

  h2{font-size: 21px;}
}

@media only screen and (max-width: 700px) {


.info .info_title > p, #Amenities .exp_title_container div > p, #Project .features_title_container > p, .gallery .gallary_title > p,
#Plan .title_container > p, #Walkthrough .walkthrough_container > p, #Virtual .title_container > p, .exterior .title_container > p, .frequently .frequently_title h2, .emi_calculator .loancheck h2, .mainbox .loanfaq h2, .guidedeheading h6, .new-sizecustom, .valuepage .value_center .value_title > p, .topcontent .top-heading h6, .career_features#Project .features_title_container h2, .careerpagenew .contact_container .bg-about h2 
 {
  font-size: 32px !important;
}

}
.pdp_hero {
    position: relative;
	width: 100%;
	height: 120vh;
	// background-image: linear-gradient(
	// 		45deg,
	// 		rgba(0, 0, 0, 0.333),
	// 		rgba(70, 70, 70, 0.062)
	// 	),
	// 	url("../../../../assets/pdp/hero.png");
    background: url("../../../../assets/pdp/hero.png"), lightgray 0% 0% / 100px 100px repeat;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
    z-index: 1;
    .pdp_decription {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        width: 290px;
        p{
            color: #fff;
            margin-bottom: 1rem;
        }
    }
}

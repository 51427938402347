.zigzagsection {

    .layout {
        display: flex;
        flex-wrap: wrap;


    }

    .blogsrow {
        display: flex;
        margin-bottom: 3rem;
        flex-wrap: wrap;



        &>div {

            @media screen and (max-width: 767px) {
                width: 100%;
                padding: 2rem 0rem 0rem 0rem !important;

            }
        }




        .txtblog {


            h2 strong,
            h2 {
                font-size: 26px;
                letter-spacing: 2px;
                font-weight: initial;
                margin-bottom: 0.8rem;
                margin-top: 2.5rem;
                font-family: 'TFArrow Medium', sans-serif;

                @media screen and (max-width: 767px) {
                    text-align: center;
                }
            }

            h4 {
                strong {
                    margin: 1rem 0;
                    display: inline-block;
                }

            }


            ol {
                list-style-position: inside;
                font-size: 22px;
                font-family: "Optima Medium";
                padding: 0rem 0rem;

                @media screen and (max-width: 767px) {
                    padding: 0rem 0rem;
                }



                li {

                    span {
                        margin-bottom: 1rem;
                        display: inline-block;
                    }
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.6;
                margin-bottom: 1rem;
                text-align: justify !important;

                

                strong {
                    margin: 0rem 0;
                    display: inline-block;
                }

                a {
                    color: #000;
                }

            }

        }
    }


    .blogsrow:nth-child(odd) .imgblog {
        text-align: center;
        margin: 0 auto;

    }

    .blogsrow:nth-child(odd) .txtblog {
        line-height: 1.6;
        padding: 2rem 0;
        white-space: pre-wrap;

    }

}


.zigzagsection .blogsrow .txtblog ol>li>p {
    display: contents;
}


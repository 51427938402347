.dropdown-menu-social {
    min-width: 2.8rem;
    padding: 2px;
}

.social-facebook {
    background-color: #3b5898;
    color: #fff;
}

.social-facebook:hover {
    background-color: #385186;
    color: #fff;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.social-twitter {
    background-color: #1ea2f2;
    color: #fff;
}

.social-twitter:hover {
    background-color: #1c99e5;
    color: #fff;
}

.social-google {
    background-color: #f4422c;
    color: #fff;
}

.social-google:hover {
    background-color: #C20806;
    color: #fff;
}

.social-linkedin {
    background-color: #0179bd;
    color: #fff;
}

.social-linkedin:hover {
    background-color: #0571ae;
    color: #fff;
}

.social-instagram {
    background-color: #7a2276;
    color: #fff;
}

.social-instagram:hover {
    background-color: #8b2a86;
    color: #fff;
}

.social-rss {
    background-color: #ee802f;
    color: #fff;
}

.social-pinterest {
    background-color: #c8232c;
    color: #fff;
}

.social-pinterest:hover {
    background-color: #ba1f27;
    color: #fff;
}

.social-youtube {
    background-color: #d30000;
    color: #fff;
}

.social-youtube:hover {
    background-color: #ff0000;
}

.social-snapchat-ghost {
    background-color: #fffc00;
    color: #fff;
}

.social-snapchat-ghost:hover {
    background-color: #fffc00;
    color: #fff;
}

.social-skype {
    background-color: #01baff;
    color: #fff;
}

.social-skype:hover {
    background-color: #06aeed;
    color: #fff;
}

.social-dribbble {
    background-color: #ff4f93;
    color: #fff;
}

.social-dribbble:hover {
    background-color: #ef4788;
    color: #fff;
}

.social-vimeo {
    background-color: #4bbdff;
    color: #fff;
}

.social-vimeo:hover {
    background-color: #40aceb;
    color: #fff;
}

.social-tumblr {
    background-color: #2c4862;
    color: #fff;
}

.social-tumblr:hover {
    background-color: #2c4862;
    color: #fff;
}

.social-vine {
    background-color: #00b489;
    color: #fff;
}

.social-vine:hover {
    background-color: #049774;
    color: #fff;
}

.social-foursquare {
    background-color: #3060df;
    color: #fff;
}

.social-foursquare:hover {
    background-color: #2e5ad1;
    color: #fff;
}

.social-stumbleupon {
    background-color: #eb4923;
    color: #fff;
}

.social-stumbleupon:hover {
    background-color: #d7421f;
    color: #fff;
}

.social-flickr {
    background-color: #f40083;
    color: #fff;
}

.social-flickr:hover {
    background-color: #df077b;
    color: #fff;
}

.social-rss {
    background-color: #ff6702;
    color: #fff;
}

.social-rss:hover {
    background-color: #ff6702;
    color: #fff;
}

.btn-group{display: none;}
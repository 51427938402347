.landmarksheelheights {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ul {
    list-style: none;


  li {
    font-size: 16px;
    font-family: "TFArrow Light";
    margin-bottom: 2rem;
    display: flex;

    strong {
      width: 30%;
      padding-right: 2rem;
    }

    span {
      width: 70%;
      float: right;
      line-height: 1.2;
    }
  }

}


@media only screen and (max-width: 700px) {
  display: block;
}

& p {
  line-height: 1.5;
}


&>div {
  flex: 1;
  padding: 8rem 2rem;


  img {
    margin-top: 2rem;
    max-width: 100%;
  }

  @media only screen and (max-width: 700px) {
    padding: 4rem 0;
  }
}

&>div:first-child {
  border-right: 0.5px solid rgba(0, 0, 0, 0.4);
  padding-left: 0;

  .paraproject {
    padding-top: 2rem;
  }

  @media only screen and (max-width: 700px) {
    border-right: 0px solid rgba(0, 0, 0, 0.4);
  }
}

&>div:last-child {
  padding-left: 4rem;

  @media only screen and (max-width: 700px) {
    padding-top: 0;
    padding-left: 0;
    position: relative;
  }
}

hr {
  width: 100%;
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  margin-bottom: 1rem;
}
}

.landmarkproject>div:first-child>p {
  position: relative;
  margin-bottom: 1.5rem;
}

.landmarkproject>div:first-child>p:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 1.2rem;
  transform: translateX(-50%);
  height: 2px;
  width: 40px;
  background-color: #005f9e;
}
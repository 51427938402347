.topcontent {

    background: rgb(0 0 0);
    color: #ffffff;

    @media only screen and (max-width: 1024px) {
        padding: 0 16px;
    }

    .top-heading {
        padding: 8rem 0 6rem;

        h6{font-size: 41px;}

        @media only screen and (max-width: 700px) {
            padding: 8rem 0 4rem;
        }


        .text-centercontent {
            text-align: center;
            margin: 0 auto;
            width: 800px;
            max-width: 100%;

            .para-detail {
                width: 600px;
                margin: 0 auto;
                max-width: 100%;


            }

        }

    }

}
.tablebg {
    background-color: #eceef0;
    padding: 5.25rem 0;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table tr:first-child th {
    background: #fff;
    text-align: center;
}

table td,
table th {
    padding: 5px 20px;
    background: #f3f6f7;
}

.main-heading-table {
    margin-bottom: 2rem;
}


@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }
}
.toparticleblogdetail {

    .top-heading {
        padding: 8rem 0 6rem;

        @media (max-width: 700px) {
            padding: 6rem 0 2rem;
        }



        .text-centercontent {
            text-align: center;
            margin: 0 auto;
            width: 1000px;
            max-width: 100%;
            padding: 0 1rem;



            .share-link {

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1rem;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #000;
                        text-decoration: none;



                        img {
                            padding-right: 1rem;
                        }
                    }

                    small {
                        padding: 0 0.5rem;
                    }
                }

            }
        }

    }

}
.management {
    width: 100%;
    background: url("../../../../assets/blog/bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    position: relative;


    .exp_slider_container {
        padding: 0 16px;

        @media only screen and (max-width: 700px) {
            padding-bottom: 4rem;
        }


    }

    .sliderbox {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 700px) {
        .swiper-slide.swiper-slide-next .sliderbox {
            padding-top: 5rem;
        }
    }


    .slider_cntent {
        position: absolute;
        bottom: 0px;
        text-align: center;
        left: 0;
        right: 0;
        height: 15%;
        padding-top: 1rem;
        background-image: linear-gradient(to bottom, rgb(0 0 0 / 20%), rgb(0, 0, 0));

        @media only screen and (max-width: 700px) {
            position: static;
            padding: 16px 0;
        }


    }

    .slider_cntent p {
        font-size: 12px;
        margin-top: 0.5em;
    }

    .exp_item {
        width: 50%;
        position: relative;

        &:hover .overlaybg {
            height: calc(100% - 0px);

            @media only screen and (max-width: 700px) {
                height: calc(100% - 74px);
            }

        }

        @media only screen and (max-width: 700px) {
            width: 100%;
        }


        img {
            object-fit: cover;
            width: 100%;
        }

        .overlaybg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: #121212;
            overflow: hidden;
            width: 100%;
            height: 0;
            transition: .5s ease;

            @media only screen and (max-width: 700px) {
                bottom: 74px;
            }

        }



        .contentonhover {
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-height: 100%;
            width: 100%;
            padding: 24px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
                background: #414141;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #ffffff;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ffffff;
                border-radius: 10px;
            }

            & p {
                padding-bottom: 1rem;
                text-align: justify;
            }

            @media only screen and (max-width: 700px) {
                padding: 10px;
            }
        }
    }



    .mang_title_container {
        text-align: center;
        max-width: 550px;
        margin: 0 auto;
        padding-top: 9.063rem;
        padding-bottom: 3rem;

        @media only screen and (max-width: 700px) {
            padding-top: 4rem;
            padding-bottom: 3rem;
            text-align: left;
            padding-left: 16px;
            padding-right: 16px;
        }

    }

    @media only screen and (max-width: 700px) {
        height: 100%;
        background-color: url("../../../../assets/nav-bg.webp");
    }

    .slider_btn {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        z-index: 5;
        cursor: pointer;
        max-width: 52px;


        @media only screen and (max-width: 700px) {
            display: none;
        }
    }


    .slider_btn.leftbtn {
        left: 5%;
    }

}
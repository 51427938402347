.principle {
    width: 100%;
    background-image: url('../../../../assets/about/principle-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 7.688rem  0;

    @media screen and (max-width: 767px) {
        background-position: 75% 60px;
        padding: 7.688rem 0 0;

        .layout_main {
            padding: 0 0 0 2rem;

        }
    }


    .principle-box {
        width: 592px;
        max-width: 100%;
        background: #ffffff;
        padding: 4.5rem 2.625rem;

        &>p {
            margin-top: 2rem;
            text-align: justify;
            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
        }

        .btn_primary {
            border: 1px solid #121212;
            color: #121212;
            margin-top: 2rem;
        }

        @media screen and (max-width: 767px) {
            padding: 2rem 1rem 2rem 2rem;
            margin-top: 20rem;
        }
    }

}
.listing-section {

  a {
    text-decoration: none;

    .relative-box:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background: rgb(0 0 0 / 60%) 50% / cover no-repeat;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }


    .section {
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      display: flex;
      justify-content: center;
      height: 100vh;
      padding: 1rem;
      position: sticky;
      width: 100%;
      z-index: -1;
      flex-direction: column;
      color: #ffffff;
      flex-wrap: wrap;
    }


    &:nth-child(2)section {
      z-index: -2;
    }

    &:nth-child(3)section {
      z-index: -3;
    }

    &:nth-child(4)section {
      z-index: -4;
    }

    &:nth-child(5)section {
      z-index: -5;
    }

    &:nth-child(6)section {
      z-index: -6;
    }

    &:nth-child(7)section {
      z-index: -7;
    }

    &:nth-child(8)section {
      z-index: -8;
    }

    &:nth-child(9)section {
      z-index: -9;
    }

    &:nth-child(10)section {
      z-index: -10;
    }
    &:nth-child(11)section {
      z-index: -11;
    }
    &:nth-child(12)section {
      z-index: -12;
    }
    &:nth-child(13)section {
      z-index: -13;
    }
    &:nth-child(14)section {
      z-index: -14;
    }
    &:nth-child(15)section {
      z-index: -15;
    }
    &:nth-child(16)section {
      z-index: -16;
    }




    .relative-box h5 {
      font-family: "TFArrow Light";
      font-size: 16px;
      font-style: normal;
      position: relative;
      line-height: 1.2;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    .relative-box h5 span {
      font-size: 20px;
    }

    .loader-one {
      margin: auto;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      animation: spinner 1s linear;
      backdrop-filter: blur(5px);
      position: relative;
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }

    .loader-one:before {
      content: '';
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      border: 4px solid transparent;
      border-top: 4px solid #ffffff;
      border-right: 4px solid #ffffff;
      border-radius: 50%;
      right: 0;
    }

    @keyframes spinner {
      0% {
        -webkit-transform: rotate(300deg);
        transform: rotate(300deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .flex-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      span {
        top: 30px;
        font-size: 16px;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 2;

  

      }
    }

    .bottom-tagline {
      position: absolute;
      bottom: 80px;
      left: 80px;
      text-align: left;
      max-width: 290px;
      width: 100%;

      h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-top: 1rem;
      }

      h6 {
        position: relative;
      }

      h6::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 32px;
        background-color: #005f9e;
      }
    }

  }
}
.facilitieseducation {
  width: 100%;
background-color:#f2f6f7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5rem 0rem;

  .info_title {
    width: 45%;
    margin-bottom: 4rem;

  }


    .col_design {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      display: -webkit-flex;
      display: flex;
      margin-left: -8px;
      margin-right: -8px;


      .col-loan {
        width: calc(33.33% - 16px);
        float: left;
        margin: 0 8px 16px;
        border-radius: 4px;
        padding: 30px 30px 30px 30px;

        @media (max-width: 767px){
            width: calc(100% - 0px);
            margin: 0 0 15px;
        }


         h4 {
          min-height: 45px;
      }
      
.col-drk {
  color: #636363;
}
.font-t4 {
  font-size: 14px;
}

    }
    .white {
        background: #fff;
    }

  }


}

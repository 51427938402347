.foottags {


    .bordertop {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        width: calc(100% - 80px);
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            width: calc(100% - 0px);

        }
    }

    .layout {
        display: flex;
        flex-wrap: wrap;

        .left-tags {
            width: 45%;
            margin-top: 2rem;

            @media screen and (max-width: 767px) {
                width: 100%
            }


            ul {

                display: flex;
                flex-wrap: wrap;

                li {
                    list-style: none;
                    width: 40%;
                    margin-bottom: 2rem;

                    span {
                        font-size: 14px;
                        text-transform: uppercase;
                        letter-spacing: 1.4px;
                        display: block;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        text-transform: capitalize;
                        display: block;
                        font-weight: 600;
                        margin-top: 0.7rem;
                        letter-spacing: inherit;
                    }

                    span:nth-child(2)>a {
                        width: 26px;
                        height: 26px;
                        display: inline-block;
                        line-height: 32px;
                        text-align: center;
                        border: 1px solid rgba(0, 0, 0, 0.4);
                        border-radius: 100%;
                        margin: 0 auto;
                        margin-right: 5px;
                    }

                    span:nth-child(2)>a img {
                        text-align: center;
                        display: -webkit-inline-box;
                        width: 16px;
                        height: 16px;
                    }

                }

            }
        }

        .right-tags {
            width: 55%;
            margin-top: 2rem;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 3rem;
                margin-top: 0;

            }


            ul {

                li {
                    list-style: none;

                    span {
                        margin-bottom: 0.7rem;
                        display: block;
                    }

                    a {
                        display: inline-block;
                        color: #000;
                        text-decoration: none;
                        border: 1px solid rgb(0 0 0 / 40%);
                        border-radius: 20px;
                        padding: 10px 16px;
                        font-weight: 600;
                        font-size: 14px;
                        margin-right: 1rem;
                        margin-bottom: 1rem;

                        @media screen and (max-width: 767px) {

                            font-size: 12px;
                            margin-right: 0.5rem;
                            margin-bottom: 0.5rem;

                        }

                    }



                }

            }
        }
    }

}
.career_features#Project {
	padding: 4rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

//

	.features_title_container {
		width: 40%;
		text-align: center;

		h2{
			margin-bottom: 1rem;
			font-size: 41px;

		}

		p{font-size: 21px;}

		@media (max-width: 1180px) {
			width: 70%;
			padding: 0 1rem;
		}
		@media (max-width: 700px) {
			width: 100%;
			padding: 0 1rem;
		}

	



	}

	.features_items {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		margin-top: 3rem;
		img{width:50px;
			max-height:50px
			}

		@media (max-width: 700px) {
			grid-template-columns: repeat(3, 1fr);
		}

		.features_item {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 180px;
			height: 180px;
			padding: 2rem;
			text-align: center;
			border-bottom: 1px solid rgba(0, 0, 0, 0.5);
			border-right: 1px solid rgba(0, 0, 0, 0.5);

			@media (max-width: 1180px) {
				width: 125px;
				height: 125px;
				padding: 1rem;
			}

			@media (max-width: 700px) {
				padding: 0rem 1rem;

				&:nth-child(3n+3) {
					border-right: none;
				}

			}


			.bullet {
				width: 42px;
				height: 42px;
				background-color:rgba(217, 217, 217, 1);
				margin-bottom: 1rem;

			}

			p{word-break: break-word;}
		}

		.features_item:last-child {
			border-bottom: none;
		}
	}
}

.career_features .features_items .features_item:nth-child(6n+6) {
	border-right: none;
}

.career_features .features_items .features_item:nth-last-child(-n + 6) {
	border-bottom: none;
}

@media (max-width: 700px) {


	.career_features .features_items .features_item:nth-last-child(-n+6) {
		border-bottom: 1px solid #ccc;
	}

	.career_features .features_items .features_item:nth-last-child(-n+3) {
		border-bottom: 0;
	}


}

.channel_partner_form{margin: 0  0 4rem;}
.title_container {
    padding: 0 0 2rem
}
.parent-divider {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -30px;
}

.divide_form-group {
    width: calc(50% - 60px);
    margin-left: 30px;
    margin-right: 30px;
}

h4.title-input {
    letter-spacing: .5px;
    margin-bottom: 20px;
}

 .form-group {
    margin-bottom: 30px;
}

.form-group input {
    background: 0 0;
    border: none;
    border-bottom: 1px solid;
    width: 100%;
}

.doc-sec {
    background: #f2f6f7;
    padding: 40px 15px;
    margin: 50px 0;
    width: 100%;
}

.doc-form {
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
}

.flex-col .form-row>.row {
    display: flex;
    flex-wrap: wrap;
}

.flex-col {
    width: calc(33.33% - 0px);
}

.col4 {
    width: 25%;
}
.col8 {
    padding-left: 1rem;
    width: 75%;
}
.title-input_bold {
    font-size: 24px;
    margin: 0;
}

input[type=file] {
    display: block;
    border: none;
    font-size: 13px;
    color: #636363;
}

// .info {
//     font-size: 13px;
//     color: #636363;
// }
.col-sm-4 {
    width: 33.33333333%;
}
.col-sm-8 {
    width: 66.66666667%;
}
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
}

.agree-txt {
    margin: 0 auto 50px;
    position: relative;
}

.agree-txt span {
    font-size: 14px;
    padding-left: 10px;
    display: block;
}
#tnc {
    cursor: pointer;
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0;
}


.radioflex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.radioflex .boxradio
 {
    margin: 10px 0 10px;
    width: calc(50% - 20px);
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 500;
}



[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: 'Optima Medium';
    font-size: 16px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #005F9E;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #005F9E;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: 'Optima Medium';
    font-size: 16px;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #005f9e;
    background: #fff;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #005F9E;
    position: absolute;
    top: 4px;
    left: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.sub-dtitle {
    font-size: 16px;
    color: #005f9f;
    min-width: 75px;
}

.wpcf7-form-control-wrap {
    position: static;
}

.wpcf7 input[type="file"] {
    cursor: pointer;
}
input[type=file] {
    display: block;
    border: none;
    font-size: 13px;
    color: #636363;
}

.info-partner {
    font-size: 13px;
    font-family: 'Optima Medium';
    color: #636363;
}
.btn-right{text-align: center;}

.channel_partner {
    color: #000000;
    border-color: #000000;
}
.clearfix:before{content: '';display: table;box-sizing: border-box;}
.clearfix:after{content: '';clear: both;box-sizing: border-box;}


@media only screen and (max-width: 1024px) {

 .bg-about {
    padding: 78px 20px;
}

.flex-col {
    width: calc(50% - 0px);
    margin-top: 10px;
}

}



@media only screen and (max-width: 700px) {


    .divide_form-group {
        width: calc(100% - 60px);
    }
    
    .flex-col {
        width: calc(100% - 0px);
        margin-top: 0;
    }
    .agree-txt {
        width: 100%;
    }
    
    .flex-col .form-row > .row {
        margin-bottom: 1rem;
    }
    
    .col4 {width: 100%;}
    .col8 {
        padding-left: 0;
        width: 100%;
        margin: 0.5rem 0 0rem;
    }


}




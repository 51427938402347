.toparticle {

    .top-heading {
        padding: 8rem 0 6rem;

        @media (max-width: 700px) {
            padding: 6rem 0 2rem;
        }



        .text-centercontent {
            text-align: center;
            margin: 0 auto;
            width: 1000px;
            max-width: 100%;

            h2 {
                margin-bottom: 1rem;
            }

        }

    }

}
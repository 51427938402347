/* Switch */
.switch {
  width: 60px;
  height: 40px;
  background-color: #a0aab2;
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;

  .handle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 40px;
  }
}
.switch[data-isOn='true'] {
  justify-content: flex-end;
  background-color: #00a19b;
}

.layout {
  @media only screen and (min-width: 1281px) {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 1280px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

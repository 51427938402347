.expraet {
	background-color: #000000;
	color: #fff;
	padding: 5rem 0;



	.exp_title_container {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 700px) {
			padding: 0 1rem;
		}


		div {
			width: 60%;
			text-align: center;

			@media (max-width: 700px) {
				width: 100%;
			}



			&>p {
				position: relative;
				margin-bottom: 1.5rem;
				font-size: 41px;
			}

			&>p:before {
				content: "";
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				height: 2px;
				width: 40px;
				background-color: #005f9e;

			}
		}
	}

	.exp_slider_container {
		margin-top: 3rem;
		position: relative;
		padding-left: 40px;

		@media only screen and (max-width: 700px) {
			padding-left: 16px;
		}

		.slider_btn {
			position: absolute;
			top: 50%;
			right: 10%;
			transform: translateY(-50%);
			z-index: 2;
			max-width: 52px;
			cursor: pointer;
			// visibility: hidden;

			@media only screen and (max-width: 700px) {
				display: none;
			}
		}

		.slider_btn.leftbtn {
			left: 10%;
		}

		.exp_item {
			img {
				width: 100%;
				object-fit: cover;
				height: 336px;
			}
		}

		p {
			text-transform: uppercase;
			margin-top: 0.3rem;
		}
	}
}
.hero_banner_title {
  padding: 2rem 2rem 1rem 2rem;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    text-transform: none;
  }
  a {
    text-decoration: none;
    button {
      display: flex;
      outline: none;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      cursor: pointer;
      font-family: 'TFArrow Light', sans-serif;
      font-size: 18px;
    }
  }
}

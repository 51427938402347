.pdp_herosrishtibanner {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../../../../assets/project-listing/Srishtibanner-detail.webp"), lightgray 0% 0% / 100px 100px repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .hero_content {
        color: #fff;
        position: relative;
        text-align: center;
        max-width: 700px;
        padding: 0 1rem;


        .detail {
            margin-bottom: 1.5rem;
            letter-spacing: 3.2px;
            text-transform: uppercase;
        }
    }

    .pdp_decription {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        width: 500px;
        max-width: 100%;
        display: flex;
        align-items: center;


        @media only screen and (max-width: 700px) {
            left: auto;
            right: auto;
            padding: 0 16px;

        }

        .bannertagline {
            max-block-size: fit-content;
            max-width: 100%;
            padding-right: 1rem;
        }


        .pdp_barcode {
            width: 300px;
            display: flex;
            max-width: 100%;
            justify-content: space-between;
            text-align: center;


            .barcode {
                display: flex;
                flex-direction: column;
                // max-width: max-content;
                color: #fff;
                font-size: 12px;
                span {
                    margin-top: 0.75rem;
                }

                img {
                    // max-width: max-content;
                    max-width: 65px;
                    margin: 0.5rem;
                }
            }
        }

        p {
            color: #fff;
            margin-bottom: 1rem;
            a{
                color: #fff;
            }
        }
    }
}
.error_page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    img {
        width: 20%;
    }
    .btn_primary {
        color: #000;
        border-color: #000;
        margin-top: 0.5rem;
        &:hover {
            width: 200px;
        }
    }
    h3 {
        margin: 0.5rem 0;
    }
}
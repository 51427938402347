.h6,
h6 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-weight: 500;

}

.text-center {
    text-align: center;
}

.toptextcolum {
    padding-top: 120px;
    padding-left: 16px;
    padding-right: 16px;
    width: 550px;
    max-width: 100%;
    margin: 0 auto 5.25rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 2rem;
    }


}

.psudo-box {
    position: relative;

    .psudo-border:before {
        position: absolute;
        content: '';
        background: #005F9E;
        width: 42px;
        height: 2px;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .title-center {
        font-size: 56px;
    }

    .flex-box {
        flex-direction: column;
    }

    .bottom-tagline h3 {
        font-size: 22px;
    }
}


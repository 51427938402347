.mainbox {
    padding: 5.25rem 0;


    @media screen and (max-width: 767px) {
            padding: 3.25rem 0;
    }

    .loanfaq {
        padding-bottom: 2rem;
        padding-left: 16px;
        padding-right: 16px;
        width: 600px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;

        h2{font-size: 41px;}

    }

    .faqlist {

        margin-top: 3rem;

        @media screen and (max-width: 767px) {
            margin-top: 0;
            margin-left: -16px;
            margin-right: -16px;
        }


        ul {
            position: relative;
            overflow-y: auto;
            max-height: 99%;

            @media screen and (max-width: 767px) {
                overflow-y: inherit;
            }

            /* width */
            ::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            li {
                list-style: none;

                &.activebg {
                    display: flex;
                    color: #ffffff;

                    @media screen and (max-width: 767px) {
                        flex-direction: column;
                        position: relative;
                    }
                }

                &.activebg .quesbox {
                    color: #ffffff;
                    background: var(--theme-dark, #121212);
                }

                &.activebg .anserbox {
                    border-left: 1px solid #ffffff94;

                    @media screen and (max-width: 767px) {
                        border-left: 0;
                        transform: translateY(0%);
                        position: relative;
                    }

                }

                &.activebg .anserbox .right-answer {
                    transform: translateX(0%);
                    transition: all 0.3s ease-in;
                    position: relative;
                    z-index: 2;

                    @media screen and (max-width: 767px) {
                        transform: inherit;

                    }

                }

                .quesbox {
                    padding: 2.625rem;
                    border-bottom: 0.5px solid rgba(0, 0, 0, 0.40);
                    width: 70%;
                    position: relative;
                    transition: all 0.3s ease-in;

                    @media screen and (max-width: 820px) {
                        width: 65%;
                    }

                    @media screen and (max-width: 767px) {
                        padding: 2rem 1rem;
                        width: 100%;
                        text-align: center;
                    }



                    .imgarrow {
                        position: absolute;
                        right: 20px;
                        top: 40%;

                        @media screen and (max-width: 767px) {
                            position: static;
                            margin: 16px auto 0;
                            text-align: center;
                            width: 32px;
                            transform: rotate(90deg);
                        }
                    }


                    h2 {
                        font-size: 2rem;
                        font-family: "TFArrow Medium";
                        padding-bottom: 0.5rem;

                        @media screen and (max-width: 820px) {
                            font-size: 1.5rem;

                        }

                        @media screen and (max-width: 767px) {
                            font-size: 24px;
                        }

                    }

                    p {
                        line-height: 160%;
                        padding-right: 5vw;

                        @media screen and (max-width: 767px) {
                            padding-right: 0;
                        }


                    }

                }

                .anserbox {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 30%;
                    height: 100%;

                    @media screen and (max-width: 820px) {
                        width: 35%;
                    }

                    @media screen and (max-width: 767px) {
                        position: absolute;
                        width: 100%;
                        transform: translateY(-100%);
                    }


                    & .right-answer {
                        transform: translateX(-100%);
                        transition: all 0.3s ease-in;
                        padding: 4rem 2rem;
                        height: 100%;
                        background: var(--theme-dark, #121212);

                        @media screen and (max-width: 820px) {
                            padding: 2rem 1rem;
                        }


                        @media screen and (max-width: 767px) {
                            padding: 2rem 1rem;
                            height: auto;
                            border-top: 0.5px solid rgba(255, 255, 255, 0.40);
                            background: var(--primary-colours-primary-dynamix-grey, #A0AAB2);
                            color: #000;
                            transform: inherit;


                        }
                    }


                    h3 {
                        font-size: 18px;
                        line-height: 140%;
                        text-transform: inherit;
                        margin-bottom: 24px;
                    }

                    ul {
                        li {
                            line-height: 160%;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-bottom: 8px;
                            position: relative;

                            @media screen and (max-width: 820px) {
                                font-size: 12px;
                            }



                            span {
                                padding-right: 12px;
                                position: absolute;
                                left: 0;
                                top: 4px;
                                @media screen and (max-width: 767px) {
                                top: 0px;
                                }

                            }

                            code {
                                font-family: "Optima", sans-serif;
                                padding-left: 1.5rem;
                            }

                        }
                    }
                }
            }


        }
    }
}
.blogcards {

    background: rgb(0 0 0);
    color: #ffffff;
    padding-bottom: 3rem;


    ul {

        list-style: none;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
        display: grid;

        @media only screen and (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);

        }

        @media only screen and (max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
        }


        li {
                        a {

                color: #ffffff;
                text-decoration: none;
                position: relative;
                display: block;
                transition: 0.5s ease;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    top: -25px;
                    border-top: 1px solid rgb(255 255 255 / 50%);
                    overflow: hidden;
                    width: 0%;
                    height: 2px;
                    transition: 0.5s ease;
                    opacity: 0;

                }

                &:hover .overlayimage:before {
                    opacity: 1;
                }

                &:hover::before {
                    opacity: 1;
                    width: 100%;
                }

                figure {

                    h3 {
                        font-family: "TFArrow Medium";
                        font-weight: 500;
                        margin-top: 1rem;
                        min-height: 58px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    span {
                        font-size: 1rem;
                        font-weight: 300;
                        margin-top: 1rem;
                        display: block;
                        font-family: "TFArrow Light";
                    }

                    figcaption {

                        .overlayimage {
                            margin-top: 4rem;
                            position: relative;
                            overflow: hidden;

                            &:before {
                                position: absolute;
                                content: '';
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                background-color: rgba(0, 0, 0, 0.5);
                                overflow: hidden;
                                width: 100%;
                                height: 100%;
                                transition: .5s ease;
                                opacity: 0;

                            }

                            @media only screen and (max-width: 700px) {
                                margin-top: 0rem;
                                margin-left: -16px;
                                margin-right: -16px;
                            }


                            img {
                                width: 100%;
                                height: 223px;
                            }
                        }



                    }
                }
            }




        }

    }
    .pagination {
        padding: 10px;
        margin: 15px 0;
        display: flex;
        justify-content: center;
      }
      .pageNumbers {
        list-style: none;
        display: flex;
        justify-content: center;
        margin-top: 65px;
        gap: 22px;
        

        @media screen and (max-width: 1024px){
         padding-left: 24px;
         padding-right: 24px;
        }
      }
      
      .pageNumbers li {
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 50%;
        height: 40px; 
        width: 40px ;
        display: flex;
        align-items: center;
        justify-content: center;
        color: gray;
        &:hover {
            border: 1px solid white;
            color: white;
        }
        
      }
      
      .pageNumbers li.active {
        border: 1px solid white;
        color: white;
      }
      
      .pageNumbers li button {
        background-color: transparent;
        border: none;
        color: gray;
        font-size: 1.5rem;
        cursor: pointer;
      }

      .pageNumbers li button:hover {
        color: white;
      }

      .pageNumbers li button:focus {
        outline: none;
      }
      
}
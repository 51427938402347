.gallery {
  margin: 4rem 0;

  .gallary_title {
    width: 40%;
    margin-bottom: 2rem;

    @media (max-width: 700px) {
      width: 100%;
    }

    &>p {
      position: relative;
      margin-bottom: 1.5rem;
      font-size: 41px;
    }

    &>p:before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 1.2rem;
      transform: translateX(-50%);
      height: 2px;
      width: 40px;
      background-color: #005f9e;
    }
  }

  .gallary_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media only screen and (max-width: 700px) {
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    @media only screen and (min-width: 700px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      margin-top: 1rem;

      .grid_col_span_2 {
        grid-column: span 2;
      }

      .grid_row_span_2 {
        grid-row: span 2;
      }
    }

    .gallary_item {
      @media only screen and (max-width: 700px) {
        max-height: 180px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.blog {
	width: 100%;
	height: 500px;
	background: url("../../../../assets/blog/bg.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;

	@media only screen and (max-width: 700px) {
		height: 100%;
		background-color: url("../../../../assets/nav-bg.webp");
	}

	.blog_container {
		padding-top: 5%;
		width: 100%;
		padding-left: 8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media only screen and (max-width: 1300px) {
			padding-left: 4rem;
		}

		@media only screen and (max-width: 700px) {
			padding-left: 0;
			flex-direction: column;
		}

		.left_container {
			width: 30%;

			.title_underline {
				position: relative;
				margin-bottom: 10px;
				font-size: 41px;
				@media only screen and (max-width: 700px) {
					font-size: 32px;
				}
			}

			.title_underline::after {
				content: "";
				position: absolute;
				bottom: -10px;
				left: 0;
				height: 2px;
				width: 32px;
				background-color: #005f9e;

				@media only screen and (max-width: 700px) {
					left: 50%;
					transform: translateX(-50%);
				}
			}

			@media only screen and (max-width: 1300px) {
				width: 25%;
			}

			@media only screen and (max-width: 700px) {
				width: 90%;
				text-align: center;
				padding: 2rem 0;
			}

			h2 {font-size: 21px;margin-top: 1rem;margin-bottom: 0.5rem;
				@media only screen and (max-width: 700px) {
					margin: 1rem 0;
				}
			}
		}

		.right_container {
			width: 60%;

			@media only screen and (max-width: 1300px) {
				width: 70%;
			}

			@media only screen and (max-width: 700px) {
				width: 100%;
				margin-bottom: 2rem;
				padding-left: 1rem;
			}

			.blog_slider {
				position: relative;

				a{color: #ffffff;}

				.slider_btn {
					position: absolute;
					top: 50%;
					right: 5%;
					transform: translateY(-50%);
					z-index: 5;
					cursor: pointer;
					max-width: 52px;

					@media only screen and (max-width: 700px) {
						display: none;
					}

				}
				
				.slider_btn.leftbtn {
					left: 5%;
				}

				.blog_item {
					// width: 288px;
					// min-height: 192px;
					position: relative;

					@media only screen and (max-width: 700px) {
						// width: 100%;
					}

					div {
						width: 100%;

						img {
							width: 100%;
							// height: 100%;
							min-height: 200px;
							max-height: 200px;
							object-fit:inherit;
							// @media only screen and (max-width: 700px) {
							// 	width: 100%;
							// 	height: 100%;
							// }
						}

						.blog_date {
							position: absolute;
							top: 0;
							right: 0;
							padding: 3px 6px;
							background-color: #005f9e;
							font-size: 12px;
							text-transform: uppercase;
						}
					}

					.blog_title {
						margin-top: 0.5rem;
						font-size: 20px;
						font-style: normal;
						font-weight: 300;
						line-height: 140%;
						letter-spacing: 0.4px;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}
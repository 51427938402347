.disclaimertxt {
    padding-top: 150px;
    max-width: 100%;
    margin: 0 auto 5.25rem;


    .text-center {
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 2rem;
       
    }


    

    .psudo-box {
        position: relative;

        .psudo-border:before {
            position: absolute;
            content: '';
            background: #005F9E;
            width: 42px;
            height: 2px;
            bottom: -5px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .para-detail{margin-top: 2rem;
    
        &>p {
            margin-bottom: 15px;
            line-height: 1.3;
            font-size: 16px;
        }
    
    }

    

    @media screen and (max-width: 767px) {
        .title-center {
            font-size: 56px;
        }

        .flex-box {
            flex-direction: column;
        }

        .bottom-tagline h3 {
            font-size: 22px;
        }
    }
}
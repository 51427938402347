.floor_designgoa {
    background-color: #000;
    color: #fff;
    padding: 4rem 0;
//
    .box {
        z-index: 99;
        .image-box {
            width: 70%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    @media (max-width: 1200px) {
        display: block;

    }


    .title_container {
        display: flex;
        align-items: center;
        justify-self: center;
        flex-direction: column;
        width: 40%;
        margin: 0 auto;
        text-align: center;

        @media (max-width: 700px) {
            width: 100%;
            padding: 0 1rem;

        }


        &>p {
            position: relative;
            margin-bottom: 1.5rem;
            font-size: 41px;
        }

        &>p:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 40px;
            background-color: #005f9e;
        }

    }


    .floor_slider {
        width: 900px;
        margin: 0 auto;
        max-width: 100%;
        position: relative;

        img.slider_btn {
            position: absolute;
            right: -7%;
            top: 44%;
            visibility: Overflow;
            max-width: 52px;
            cursor: pointer;

            @media (max-width: 700px) {

                display: none;
            }

        }
        img.slider_btn.leftbtn {
            left: -7%;
        }
        @media (max-width: 700px) {
            padding-left: 16px;
        }




        .floor_items {
            margin-top: 2rem;
            text-align: center;

            p {
                margin-top: 0.5rem;
            }

        }
    }

    .swiper-wrapper .floor_item img {
        max-height: 195px;

    }

}
.admin_login {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('../../assets/bg_login.png');
  background-position: top;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  .admin_login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .admin_login_wrapper {
      padding: 78px;
      background-color: #fff;
      margin-top: 2rem;
      h2 {
        text-transform: capitalize;
      }
      form {
        input {
          display: block;
          width: 100%;
          width: 416px;
          height: 52px;
          border: none;
          outline: none;
          border-bottom: 1px solid #121212;
          margin-top:  1.5rem;
        }
        .btn_primary {
          width: 100%;
          color: #000;
          border-color: #000;
          margin-top: 1.5rem;
        }
      }
      a {
        font-family: 'TFArrow Medium', sans-serif;
        color: #121212;
        text-decoration: none;
        text-transform: uppercase;
        float: right;
        font-size: 16px;
        margin-top: 2rem;
      }
    }
  }
}


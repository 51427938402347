.listing-sectioncuurent {

  a {
    text-decoration: none;

    .relative-box:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5) 50% / cover no-repeat;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

    }


    .section {
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      bottom: 0;
      display: flex;
      justify-content: center;
      height: 100vh;
      padding: 1rem;
      position: sticky;
      width: 100%;
      z-index: -1;
      flex-direction: column;
      color: #ffffff;
      flex-wrap: wrap;
      
      @media screen and (max-width: 767px) {
      height: 70vh;
      }

    }


    &:nth-child(2)section {
      z-index: -2;
    }

    &:nth-child(3)section {
      z-index: -3;
    }

    &:nth-child(4)section {
      z-index: -4;
    }

    &:nth-child(5)section {
      z-index: -5;
    }

    &:nth-child(6)section {
      z-index: -6;
    }

    &:nth-child(7)section {
      z-index: -7;
    }

    &:nth-child(8)section {
      z-index: -8;
    }

    &:nth-child(9)section {
      z-index: -9;
    }

    &:nth-child(10)section {
      z-index: -10;
    }





    .relative-box h5 {
      font-family: "TFArrow Light";
      font-size: 16px;
      font-style: normal;
      position: relative;
      line-height: 1.2;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    .relative-box h5 span {
      font-size: 20px;
    }

    .loader-one {
      margin: auto;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      animation: spinner 1s linear;
      backdrop-filter: blur(5px);
      // margin-right: 30px;
      position: relative;
      margin-bottom: 15px;


      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }

    .loader-one:before {
      content: '';
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      border: 4px solid transparent;
      border-top: 4px solid #ffffff;
      border-right: 4px solid #ffffff;
      border-radius: 50%;
      right: 0;
    }

    @keyframes spinner {
      0% {
        -webkit-transform: rotate(300deg);
        transform: rotate(300deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .flex-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      span {
        top: 30px;
        font-size: 16px;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 2;

      }
    }

    .bottom-tagline {
      position: absolute;
      bottom: 80px;
      left: 80px;
      text-align: left;
      max-width: 290px;
      width: 100%;

      h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-top: 1rem;
      }

      h6 {
        position: relative;
      }

      h6::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 32px;
        background-color: #005f9e;
      }
    }

  }
}

.chart-two svg .chart-one svg .circle-foreground{
  r: 99.5px;
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: #305556;
  stroke-width: 1px;
}

.chart-two {
  width: 200px;
  height: 200px;
  margin: 0;
  position: relative;
}
.chart-two.animate svg .circle-foreground {
  animation: offset 3s ease-in-out forwards;
  animation-delay: 1s;
}
.chart-two.animate figcaption:after {
  animation: chart-two-label 3s steps(50) forwards;
  animation-delay: 1s;
}
.chart-two svg {
  width: 100%;
  height: 100%;
}
.chart-two svg .circle-background, .chart-two svg .circle-foreground {
  r: 92.5px;
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: #305556;
  stroke-width: 15px;
}
.chart-two svg .circle-foreground {
  stroke: #d0f09e;
  stroke-dasharray: 290.45px 580.9px;
  stroke-dashoffset: 290.45px;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
.chart-two figcaption {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  overflow: hidden;
  text-align: center;
  color: #c6e8d7;
  position: absolute;
  top: calc(50% - 1.25rem);
  left: 0;
  font-size: 0;
}
.chart-two figcaption:after {
  display: inline-block;
  content: '0%\a1%\a2%\a3%\a4%\a5%\a6%\a7%\a8%\a9%\a10%\a11%\a12%\a13%\a14%\a15%\a16%\a17%\a18%\a19%\a20%\a21%\a22%\a23%\a24%\a25%\a26%\a27%\a28%\a29%\a30%\a31%\a32%\a33%\a34%\a35%\a36%\a37%\a38%\a39%\a40%\a41%\a42%\a43%\a44%\a45%\a46%\a47%\a48%\a49%\a50%\a51%\a52%\a53%\a54%\a55%\a56%\a57%\a58%\a59%\a60%\a61%\a62%\a63%\a64%\a65%\a66%\a67%\a68%\a69%\a70%\a71%\a72%\a73%\a74%\a75%\a76%\a77%\a78%\a79%\a80%\a81%\a82%\a83%\a84%\a85%\a86%\a87%\a88%\a89%\a90%\a91%\a92%\a93%\a94%\a95%\a96%\a97%\a98%\a99%\a100%\a';
  white-space: pre;
  font-size: 2.5rem;
  line-height: 2.5rem;
}
@keyframes chart-two-label {
  100% {
    transform: translateY(-125rem);
  }
}

@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}



.scrolltext {
    padding: 8.75rem 0;

    @media screen and (max-width: 767px) {
        padding: 4rem 0;
    }

    p {
        text-align: center;
        font-size: 56px;
        font-weight: 300;
        line-height: 140%;
        text-transform: uppercase;
        font-family: "TFArrow Light";

        @media screen and (max-width: 767px) {
            font-size: 40px;
        }

    }

}
.galleryrow {
    background: rgba(18, 18, 18, 1);
    color: #ffffff;
    padding-bottom: 3rem;


    .gallery-heading {
        padding: 8rem 0 4rem;
    }

    .text-centercontent {
        text-align: center;
        margin: 0 auto;
        width: 550px;
        max-width: 100%;

        @media only screen and (max-width: 700px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }



    .cardcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;

        .card {
            flex: 1;
            transition: all 0.8s ease-in-out;
            height: 75vmin;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @media only screen and (max-width: 820px) {
                    flex: 100%;
            }



            &:before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5) 50%/cover no-repeat;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
            }

            .card__head {
                transition: all 0.5s ease-in-out;
                min-width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                white-space: nowrap;
                color: #FFF;
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
                letter-spacing: 4.8px;
                text-transform: uppercase;
                font-family: "TFArrow Light";
                z-index: 3;
                transform: translate(-50%, -50%);

                h5 {
                    font-size: 16px;
                    font-style: normal;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    font-weight: 300;
                }

            }

            &:hover {
                flex-grow: 8;

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.8s ease-in-out;
            }


        }
    }




}
.megamenu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0);
  // transition: all 700ms ease-in-out;
  // transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  transition: all .50s cubic-bezier(.62, 0, .8, .38);
  // transition:   0.5s ease-in-out;
  clip-path: circle(30px at calc(100% - 65px) 65px);
  visibility: hidden;
  z-index: 9999999999;


  >span {
    display: none;
    color: #fff;
    position: absolute;
    top: 30px;
    left: 16px;
    font-size: 18px;
    visibility: hidden;
  }


  .close_menu_btn {
    position: absolute;
    top: 24px;
    right: 60px;
    cursor: pointer;
    z-index: 999;

    @media only screen and (max-width: 980px) {
      top: 10px;
      right: 10px;
    }

    @media only screen and (max-width: 700px) {
      top: 16px;
      right: 25px;
    }

  }

  .layout {
    height: 100%;

    @media only screen and (max-width: 700px) {
      padding: 0;
    }
  }

  .menurow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    color: #ffffff;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      flex-wrap: unset;
      height: calc(85% - 25px);
      overflow-y: auto;
      margin-top: 50px;


    }

    .menuleft {
      width: 35%;
      border-right: 1px solid rgb(255 255 255 / 40%);
      padding-top: 3rem;

      @media only screen and (max-width: 700px) {
        width: 100%;
        padding-top: 1rem;
        order: 2;
        border-right: 0;
        padding-left: 16px;
        padding-right: 16px;

      }


      .feat-img {

        a {

          .realtive-box {
            position: relative;
            color: #fff;
            max-width: 400px;

            &:before {
              position: absolute;
              content: '';
              left: 0;
              right: 0;
              background: rgb(0 0 0 / 60%);
              height: 100%;
              width: 100%;
            }

            strong {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              // z-index: 2;
              font-size: 30px;
              letter-spacing: 6px;
              text-transform: uppercase;
              font-family: 'Optima';

              @media only screen and (max-width: 700px) {
                font-size: 20px;
              }


              small {
                letter-spacing: 4px;
                display: block;
                font-size: 18px;
                text-align: center;
                margin-top: 20px;
                font-weight: 500;
                font-family: 'TFArrow Medium';

                img{
                  position: relative;
                  top: 4px;
                }
          

                @media only screen and (max-width: 700px) {
                  font-size: 16px;
                  margin-top: 10px;
                }

              }
            }

          }




        }

        @media only screen and (max-width: 700px) {
          max-width: 60vw;
          margin: 0 auto;
        }
      }

      .psudo-box {
        .psudo-border:before {
          right: auto;
        }

        p {
          margin-bottom: 2rem;
          letter-spacing: 1.4px;
          text-transform: uppercase;
        }
      }
    }

    .menuright {
      width: 65%;
      padding-top: 3rem;
      padding-left: 4rem;
      height: 100%;
      position: relative;

      @media only screen and (max-width: 980px) {
        padding: 2rem 1rem 1rem;
        position: static;

      }

      @media only screen and (max-width: 700px) {
        width: 100%;
        padding: 2rem 0 1rem;

      }


      .psudo-box {
        position: relative;






        .psudo-border:before {
          right: auto;
        }

        p {
          margin-bottom: 2rem;
          letter-spacing: 1.4px;
          text-transform: uppercase;

          @media only screen and (max-width: 700px) {
            margin:0.8rem 0;

          }

          a {
            color: #fff;
            font-size: 14px;
            text-decoration: none;
          }


        }
      }

      .menu_linking {
        display: flex;
        flex-wrap: wrap;

        ul {
          list-style: none;
          flex-basis: 33.33%;
          margin-bottom: 2rem;


          @media only screen and (max-width: 700px) {
            .mobilehidemenu {
              li {
                margin-bottom: 5px;
              }
            }

          }


          &:nth-child(1) li>a {
            // font-size: 1.2em;
          }




          @media only screen and (max-width: 700px) {
            flex-basis: 100%;
            margin-bottom: 0rem;
            padding-left: 16px;
            padding-right: 16px;



            &:nth-child(1) .psudo-box {
              display: none;
            }

            .mobilehidemenu {
              max-height: 0;
              overflow: hidden;
              padding-left: 0;
              transition: all .5s ease-in-out;
            }

            .submenushow {
              max-height: 100%;
            }

          }



          li {
            letter-spacing: 1.8px;
            text-transform: uppercase;
            margin-bottom: 24px;

            @media (min-width: 1200px) and (max-width: 1366px) {
              margin-bottom: 10px;
            }

            @media only screen and (max-width: 700px) {
              margin-bottom: 16px;
            }


            a {
              color: #ffffff;
              text-decoration: none;

              @media only screen and (max-width: 700px) {
                font-size: 13px;

              }

            }
          }
        }
      }

      .megabottomtag {
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        align-items: center;
        padding: 32px 3rem;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        justify-content: space-between;
        margin-left: -4rem;
        margin-right: -40px;
        position: absolute;
        width: 100%;
        bottom: 0;

        @media only screen and (max-width: 980px) {
          padding: 15px 16px 10px;
          margin-left: auto;
          margin-right: auto;
          border-top: 0;
          left: 0;
          right: 0;
          background-color: black;
        }

        .rgs-btn {
          display: flex;
          align-items: center;
          width: 65%;

          @media only screen and (max-width: 980px) {
            width: 100%;
            flex-wrap: wrap;
          }

          p {
            font-size: 18px;
            font-family: 'TFArrow Light';
            letter-spacing: 0.36px;
            padding-right: 32px;
            max-width: 266px;

            @media only screen and (max-width: 980px) {
              padding-right: inherit;
              flex: 1;
              font-size: 16px;
            }
          }

          .btn_primary {
            flex: 1;
          
            a{color: #ffffff;}

          }

        }


        .footer_logo {
          display: flex;
          align-items: center;
          width: 35%;
          justify-content: right;


          span {
            font-size: 14px;
          }

          img {
            padding-left: 14px;
          }

          @media only screen and (max-width: 980px) {
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            margin-top: 0.5rem;

            span {
              flex: 1;
            }

            .social_tag {
              flex: 1;
            }

          }

        }
      }
    }
  }
}

.megamenu::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.megamenu::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.megamenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}

.megamenu_active {
  visibility: visible;
  clip-path: circle(100%);

  @media only screen and (max-width: 767px) {

    >span {
      display: block;
      visibility: visible;
    }
  }

}

.bordermobile {
  display: none;
}

@media only screen and (max-width: 700px) {

  .bordermobile {
    display: block;
  }

  .bordermobile {
    width: 100%;
    margin: 15px 0;
    border-color: #ffffff42;
  }

  .submenushow .mobilehidemenu {
    max-height: 100% !important;
  }

  .btn-click {
    cursor: pointer;
    height: 21px;
    position: absolute;
    width: 21px;
    top: 5px;
    right: 15px;
  }

  .btn-click:before,
  .btn-click:after {
    background: #ffffff;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
    transition: transform 500ms ease;
  }

  .btn-click:before {
    transform: rotate(180deg);
  }

  .btn-click:after {
    transform: rotate(90deg);
  }

  .submenushow .btn-click:after {
    transform-origin: center;
    height: 0;
  }

}
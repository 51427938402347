.fullbanner {
    position: relative;

    img {
        width: 100%;
        min-height: 600px;

    }

    @media screen and (max-width: 767px) {
        img {
            height: 800px;
            object-fit: cover;
        }
    }

}



.bannerdetail-abs {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    color: #ffffff;

   



    p {
        margin-bottom: 1rem;
        font-size: 24px;
        font-style: normal;
        line-height: 140%;
        font-family: "TFArrow Medium";
    

        @media screen and (max-width: 1024px) {
            font-size: 20px;
            padding-left: 16px;
            padding-right: 16px;
            

        }

    }


}
// @media (max-width: 767px) {
//     .bannerdetail-abs {
//         margin-bottom: 50px;
//         margin-top: 50px;
//     }

// }
.frequently {
  padding: 5rem 0rem;

  .frequently_title {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    &>p {
      position: relative;
      width: 60%;
      margin: auto;

      @media only screen and (max-width: 700px) {
        width: 100%;
      }


    }

    h2{font-size: 41px;}


    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }

  hr {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 0.5rem;

    @media only screen and (max-width: 700px) {
      margin-left: -16px;
      margin-right: -16px;
    }

  }

  .dropdown_container {
    margin-top: 3rem;

    .dropdown_item {
      .dropdown_title_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @media only screen and (max-width: 700px) {
          margin: 12px 0;
        }


        .dropdown_title {
          display: flex;
          align-items: center;
          padding: 1.1rem 0;
          gap: 1rem;

          @media only screen and (max-width: 700px) {
            padding: 1.1rem 8px;
          }



        }
      }
    }

    .info_content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      padding-left: 20px;


      .para-ans {
        font-size: 16px;
        line-height: 1.4;
        margin-left: -20px;
      }

      li {
        margin-top: 0.5rem;
      }
    }

    .info_active {
      height: auto;
      max-height: 500px;
      transition: all 0.5s ease-in;

      @media only screen and (max-width: 700px) {
        margin-top: 24px;
        max-height: 960px;

      }

    }
  }
}
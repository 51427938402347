@media screen and (max-width: 767px) {

    .fullbanner img {
        height: 900px;
        object-fit: cover;
    }

}


.bannerdetail {
    display: flex;
    padding: 5.25rem 0;
    gap: 2rem;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
        padding: 2.25rem 0;
        gap: 1rem;
    }


    p {
        line-height: 1.4;
        margin-bottom: 1rem;
        text-align: justify;
    }

}
.disclaimertxt {
  padding-top: 150px;
  max-width: 100%;
  margin: 0 auto 5.25rem;

  .text-center {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }

  .psudo-box {
    position: relative;

    .psudo-border:before {
      position: absolute;
      content: '';
      background: #005f9e;
      width: 42px;
      height: 2px;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .para-detail {
    margin-top: 2rem;

    &>p {
      margin-bottom: 15px;
      line-height: 1.3;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .title-center {
      font-size: 56px;
    }

    .flex-box {
      flex-direction: column;
    }

    .bottom-tagline h3 {
      font-size: 22px;
    }
  }
}

.gallary .desk-gallary figure .fancybox .rel-cap {
  position: relative;
  overflow: hidden;
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1);
  -webkit-box-shadow:
    0 15px 30px 0 rgba(0, 0, 0, 0.02),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
  box-shadow:
    0 15px 30px 0 rgba(0, 0, 0, 0.02),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
}

.gallary .desk-gallary figure .fancybox .rel-cap figcaption {
  background: #fff;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 600;
}

.banner {
  min-height: auto;
  position: relative;
}

.fancybox-title-float-wrap {
  right: 0;
  z-index: 8050;
  text-align: center;
  width: 100%;
  left: 0;
  margin: 0;
}

.fancybox-title-float-wrap .child {
  border-radius: 0;
  font-size: 20px;
  padding: 15px 50px;
  margin-right: 0;
  width: 100%;
  margin: 0;
}

.terms-condtion-padd {
  padding-top: 120px;
}

.terms-condtion-padd h3 {
  margin-bottom: 30px;
}

ol {
  margin: 0;
  padding: 0;
  counter-reset: item;
}

ol li,
ol li>b {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  color: #000000;
  list-style: none;
}

// li:before {
//   content: counters(item, '.') ' ';
//   counter-increment: item;
//   position: absolute;
// }

ol li>b {
  padding-left: 50px;
}

ol li p {
  padding-left: 50px;
  font-size: 16px;
  color: #000000;
  margin: 0;
}

.inline-para {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.alfa-order {
  position: absolute;
  left: auto;
}

.alfa-detail {
  padding-left: 30px;
  display: inline-block;
}

.body-terms p {
  display: inline-flex;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.5;
}

.body-terms h4 {
  display: inline-flex;
  margin: 10px 0 10px;
}

.body-terms h4 span {
  padding-right: 3rem;

  width: 40px;
  ;
}

.body-terms p span {
  padding-right: 3rem;
  width: 40px;
}

.body-terms p b,
.body-terms p strong {
  font-weight: 700;
  display: contents;
}

.body-terms p .single-para {
  padding-right: 4.2rem;
}

.center-para {
  text-align: center;
}

.center-para-star {
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 767px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 0;
  }

  .owl-carousel .owl-nav button.owl-next {
    right: 0px;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 1024px) {

  .Amenities_left img,
  .Amenities_right img {
    margin: 0 auto;
  }

  .pad-remove {
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .mobi-gallery .owl-carousel .owl-nav button.owl-next {
    right: 0;
  }

  .mobi-gallery .owl-carousel .owl-nav button.owl-prev {
    left: 0px;
  }
}

span.title-bold+p {
  margin: 0;
}

li.hidden-sec {
  visibility: hidden;
}
.educationpgg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-top: 5rem;

.hidedesk{
  display: none;
}

  @media only screen and (max-width: 700px) {
    display: block;
    .hidedesk{
    display: block;
    text-align: center;
    }
    .hidemob{
    display: none;
    }
 

  }

  & p {
    line-height: 1.5;
  }


  &>div {
    flex: 1;
    padding: 5rem 0;

    img {
      margin-top: 2rem;
      max-width: 100%;
    }

    @media only screen and (max-width: 700px) {
      padding: 4rem 0;
    }
  }



  &>div:last-child {
    padding-right: 4rem;

    h2{

      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 700px) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  hr {
    width: 100%;
  }

  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin-bottom: 1rem;
  }
  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin-bottom: 1rem;

a{text-decoration: underline;color: #000;}

  }
}

.landmark>div:first-child>p {
  position: relative;
  margin-bottom: 1.5rem;
}

.landmark>div:first-child>p:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 1.2rem;
  transform: translateX(-50%);
  height: 2px;
  width: 40px;
  background-color: #005f9e;
}
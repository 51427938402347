.virtual_tour {
	margin: 6rem 0 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.title_container {
		width: 50%;
		text-align: center;
		@media (max-width: 700px) {
		width: 100%;
		padding: 0 1rem;
		}

		&>p {
            position: relative;
            margin-bottom: 1.5rem;
        }

        &>p:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 40px;
            background-color: #005f9e;
        }

	}
	.virtual_image {
		margin-top: 2rem;
		width: 100%;
		height: 80vh;
		background: url("../../../../assets/pdp/livingroom.png"),
			lightgray 0% 0% / 100px 100px repeat;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

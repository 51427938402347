.admin_about_section {
  .about_container {
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    .input_text_container {
      flex: 2;
      label {
        display: block;
        font-family: 'TFArrow Light', sans-serif;
      }
      input,
      textarea {
        font-family: 'TFArrow Light', sans-serif;
        font-size: 18px;
        width: 100%;
        height: 48px;
        background: #f0f0f0;
        border: none;
        outline: none;
        border-radius: 2px;
        margin: 0.5rem 0 1rem 0;
        padding: 1rem;
      }
      textarea {
        height: 300px;
        resize: none;
      }
    }
    .input_file_container {
      height: fit-content;
      flex: 1;
      border: 1px dashed #858585;
      input {
        display: none;
      }
      .input_file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 421.53px;
        height: 311.442px;
        text-align: center;
        padding: 3rem;
        cursor: pointer;
        img {
          width: 48px;
          height: 48px;
        }
        .file_title {
          font-size: 18px;
          margin: 0.5rem 0;
        }
      }
    }
  }
}

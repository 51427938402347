.admin_hero_banner_container {
  margin-bottom: 2rem;
  .listings {
    table {
      font-family: 'TFArrow Light', sans-serif;
      border-collapse: collapse;
      width: 100%;
      td,
      th {
        text-align: left;
        padding: 8px;
        .hero_banner {
          width: 65px;
          height: 65px;
        }
      }
      .action_icn {
        width: 32px;
        height: 32px;
        padding: 5px;
        border: 0.435px solid #a0aab2;
        box-shadow: 2.176870822906494px 4.353741645812988px 8.707483291625977px 0px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        margin-right: 0.5rem;
        cursor: pointer;
      }
      tr:first-child {
        border-bottom: 0.5px solid rgba(65, 65, 65, 0.4);
        th {
          padding: 1rem 0;
        }
      }
      tr:nth-child(even) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        border-bottom: 0.5px solid rgba(65, 65, 65, 0.4);
      }
    }
  }
}

.pdp_herochandiheights {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../../../../assets/pdp/pdpbanner/lakshchandi-apts-banner.webp"), lightgray 0% 0% / 100px 100px repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .hero_content {
        color: #fff;
        position: relative;
        text-align: center;
        max-width: 700px;
        padding: 0 1rem;

        
.detail {
    margin-bottom: 1.5rem;
    letter-spacing: 3.2px;
    text-transform: uppercase;
}
    }

    .pdp_decription {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        width: 290px;

        p {
            color: #fff;
            margin-bottom: 1rem;
        }
    }
}
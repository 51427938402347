.investorcards {
    background: rgba(18, 18, 18, 1);
    color: #ffffff;
    padding-bottom: 3rem;


    .invest-heading {
        padding: 8rem 0 4rem;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);

        h6{font-size: 41px;}
    }

    .text-centercontent {
        text-align: center;
        margin: 0 auto;
        width: 550px;
        max-width: 100%;

        @media only screen and (max-width: 700px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .investor-cards {
        padding-bottom: 2rem;

        .cards-row {
            display: flex;
            flex-wrap: wrap;
            padding: 2rem 0;

            &>div {
                flex: 1;
            }

            @media only screen and (max-width: 700px) {
                flex-direction: column;
            }
        }

        .left-heading {

            @media only screen and (max-width: 700px) {

                .resourcescards .investor-cards .left-heading {
                    width: 80%;
                }
            }

            h4 {
                font-family: "TFArrow Light";
                font-size: 32px;
                font-weight: 300;

                @media only screen and (max-width: 700px) {
                    font-size: 26px;
                }

            }
        }

        .right-select {

            @media only screen and (max-width: 700px) {
                visibility: hidden;
            }

            .option {
                width: 250px;
                max-width: 100%;
                text-align: right;
                float: right;

                select {
                    width: 100%;
                    padding: 10px 0;
                    border: none;
                    outline: none;
                    background: transparent;
                    color: #fff;
                    font-family: "Optima", sans-serif;
                    font-size: 16px;
                    text-transform: uppercase;
                    border-bottom: 1px solid #656565;
                    cursor: pointer;

                    option {
                        background: transparent;
                        color: #000;
                        padding: 10px;
                        cursor: pointer;
                    }
                }

            }

        }
    }

    .cards-listing {


        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            @media only screen and (max-width: 700px) {
                gap: 0.4em;
                margin: 0 -16px;
            }

            li {
                list-style: none;
                width: calc(50% - 0.5rem);
                position: relative;

                @media only screen and (max-width: 700px) {
                    width: calc(100%);

                }



                a {
                    color: #ffffff;
                    text-decoration: none;
                    background: rgba(255, 255, 255, 0.2);
                    display: block;
                    padding: 1.5rem 1.5rem;


                    label {
                        position: absolute;
                        right: 2rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    h6 {

                        .inline-alter {

                            font-size: 1.375rem;
                            font-weight: 300;
                            color: #ffffff;
                            vertical-align: top;
                            font-family: "TFArrow Light";
                            overflow: hidden;
                            position: relative;
                            display: inline-block;

                            &::before,
                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                left: 0;
                            }

                            &::after {
                                content: attr(data-replace);
                                height: 100%;
                                top: 0;
                                transform-origin: 100% 50%;
                                transform: translate3d(0%, 100%, 0);
                                transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
                            }

                            @media only screen and (max-width: 700px) {
                                font-size: 1rem;
                            }

                            small {
                                display: inline-block;
                                transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
                                font-size: 1.375rem;
                                font-weight: 300;
                                color: #ffffff;
                                font-family: "TFArrow Light";

                                @media only screen and (max-width: 700px) {
                                    font-size: 1rem;
                                }
                            }
                        }

                    }

                    span.dte-mnth {
                        font-size: 0.75rem;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        img {
                            padding-right: 0.50rem;
                        }

                        small {
                            padding: 0 0.50rem;
                            font-size: 0.75rem;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                        }
                    }



                    &:hover .inline-alter::after {
                        transform: translate3d(0, 0, 0);
                    }


                    &:hover .inline-alter small {
                        transform: translate3d(0%, -100%, 0)
                    }


                }
            }
        }
    }


}
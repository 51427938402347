.admin_sidebar {
  .sidebar_container {
    margin: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sidebar_links {
    margin: 0 1.5rem;
    text-transform: uppercase;
    p {
      font-size: 14px;
    }
    .sidebar_item {
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        p{
            color: #fff;
        }
      }
      .sidebar_item_container {
        display: flex;
        justify-content: space-between;
        .sidebar_title {
          display: flex;
          align-items: center;
          gap: 10px;
          .mark_bullet {
            width: 18px;
            height: 18px;
            background-color: #d9d9d9;
          }
        }
        img {
          cursor: pointer;
        }
      }
      .reveal_sections {
        margin-left: 2rem;
        p {
          margin-top: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}

.top_container_s {
  position: relative;
}

.top_container_s::after {
  display: inline-block;
  -webkit-animation: scrolling-left1 50s linear infinite;
  animation: scrolling-left1 50s linear infinite;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-left: 0;
  border-right: 0;
  top: 213px;
}

@keyframes scrolling-left1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.top_container_animation{
  
   animation: my-animation 100s linear infinite;

   &:hover{
    animation-play-state: paused;
    cursor: pointer;
   }
}
.as {
  display: flex;
  height: 450px;
  //overflow: hidden;
  // gap: 4rem;
  @media only screen and (max-width: 700px) {
    height: 400px;
  }
}
.timeline {
  margin: 2rem 0 4rem 0;
  position: relative;

  .timeline_top_container {
    p {
      margin-top: 0.5rem;
    }
  }

  .timeline_bottom_container {
    p {
      margin-bottom: 0.5rem;
    }
  }

  .timeline_item {
    display: flex;
    padding-right: 4rem;
    .timeline_line {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      .bullet_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        border: 1px solid #000;
        border-radius: 50%;
      }
      .bullet {
        width: 5px;
        height: 5px;
        background: #000;
        border-radius: 50%;
      }
      .line {
        width: 1px;
        height: 120px;
        background: #000;
      }
    }
    .timeline_container {
      padding-left: 1rem;
      width: 350px;
      // height: 150px;
      display: flex;
      //align-items: center;
      justify-content: space-between;
      gap: 1rem;
      //border: 1px solid #000;

      & > div {
        width: 50%;
      }

      .timeline_img_down {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .timeline_content {
        h3 {
          font-family: 'TFArrow Bold', sans-serif;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  .timeline_item_down {
    position: absolute;
    top: 135px;
    left: -200px;
    .timeline_container {
      .timeline_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 1rem;

      }
      .timeline_img {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .timeline_item_doted {
    position: relative;
  }
  .timeline_item_doted::after {
    border-top: 2px dashed #000;
    list-style: none;
    //border-style: dashed;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-left: 0;
    border-right: 0;
    top: 141px;
  }
}
@keyframes my-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-700px * 7));
  }
}

.exterior {
	width: 100%;
	padding: 6rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #000;
	color: #fff;
	.title_container {
		width: 30%;
		text-align: center;
     
		@media (max-width: 700px) {
		width: 100%;
		padding: 0 1rem;
		}

		&>p {
            position: relative;
            margin-bottom: 1.5rem;
        }

        &>p:before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 40px;
            background-color: #005f9e;
        }

		
	}
	.virtual_image {
		width: 100%;
		height: 80vh;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.layout_main {
	max-width: 1130px;
	margin: 0 auto;

	@media only screen and (max-width: 1280px) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media only screen and (max-width: 700px) {
		padding: 0 1rem;
	}
}
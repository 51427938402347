.walkthroughfeature {
    width: 100%;
    height: 100vh;

    background: url("../../../../assets/pdp/walkthrough-thumbnail.jpg"), lightgray -673.103px -52.621px / 193.487% 114.516% no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 700px) {
        height: 60vh;

    }


    .walkthrough_container {
        text-align: center;
        color: #fff;
        width: 50%;
        position: relative;

        &>p {
            position: relative;
            margin-bottom: 1.5rem;
            font-size: 41px;

            @media (max-width: 700px) {
                font-size: 32px;
    
            }

        }

        &>p:before {
            content:"";
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 40px;
            background-color: #005f9e;
        }

        .mt-2 {
            margin-top: 2rem;
        }

        @media (max-width: 700px) {
            width: 100%;
            padding: 0 1rem;

        }

        h1 {
            margin: 1rem 0;
        }
    }
}
footer {
  background-color: #121212;
  padding: 78px 0 40px 0;
  color: #fff;

  @media only screen and (max-width: 1200px) {
    padding: 78px 0px 40px 0px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  .link-text a {
    color: white;
  }


  .footer_container {
    .footer_wrapper {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }

      ul {
        flex: 1;
        list-style-type: none;

        @media only screen and (max-width: 700px) {
          margin-bottom: 2rem;
        }

        .titleheading {
          margin-top: 20px;

          a{font-size: 20px;}
        }

        li {
          margin-top: 0.5rem;

          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
            text-decoration: none;
          }
        }

        li:first-child {
          @media only screen and (max-width: 700px) {
            border-top: 1px solid #565656;
            padding-top: 0.5rem;
          }
        }

        li:first-child a {
          font-size: 20px;

          @media only screen and (max-width: 700px) {
            font-size: 13px;
          }
        }

        p {
          margin-top: 0.5rem;
        }

        .footer_logo {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }

      .active_links {
        display: block;
      }
    }

    .footer_desktop {
      @media only screen and (max-width: 1200px) {
        padding: 0;
      }

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    .footer_mobile {
      display: none;

      @media only screen and (max-width: 700px) {
        display: block;
      }

      .mobile_pannel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.5rem;

        p {
          font-size: 14px;
          text-transform: uppercase;
        }
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
      }

      .active_footer {
        height: auto;
        max-height: 200px;
        transition: all 0.5s cubic-bezier(1, 0, 1, 0);
      }

      .link_head a {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #fff;
        text-decoration: none;
      }

      .mobile_contact {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .copyright {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 700px) {
      text-align: center;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 5rem;
    }

    p {
      font-size: 10px;
    }

    p a {
      color: white;
    }

    p p {
      margin-bottom: 1rem;
    }

    p p a {
      color: white;
    }
  }

}

.disclaimercontent h3 {
  font-family: 'Optima Medium';
  text-align: center;
}

.disclaimercontent {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  color: #434343;
  font-size: 12px;
  padding: 3%;
}

.disclaimercontent div.text {
  height: 500px;
  overflow: auto;
  margin-top: 20px;
}

.disclaimercontent div.text::-webkit-scrollbar {
  width: 12px;
}

.disclaimercontent div.text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #005f9f;
}

.disclaimercontent div.text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.disclaimercontent div.text p {
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 5px;
  margin-right: 5%;
}

.disclaimercontent div.text ul {
  margin: 0;
  padding: 0;
}

.dis_popup .diclaimer-ul {
  font-size: 14px;
  line-height: 19px;
}


.accept {
  background-color: #005f9f;
  color: #fff;
  font-size: 15px;
  letter-spacing: 2px;
  margin: 0;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  width: 100px;
  margin: 0 auto;
  margin-top: 2%;
  padding: 5px;
  text-align: center;
  float: none;
  opacity: 1;
}

.trigger {
  text-align: center;
  padding: 7px 13px;
  background: #3e3e3e;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-family: cursive;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 600px;
  border-radius: 0.5rem;
  color: #374151;
  z-index: 9;

  @media screen and (max-width: 767px) {
    width: 360px;
    padding: 1rem 0rem;
  }

}

@media (max-width: 767px) {
  .modal-content {
    width: 90%;
    padding: 1rem 1rem;
    top: 48%;
    left: 50%;
    height: 600px;

  }

  .disclaimercontent {
    padding: 1%;
  }

  .disclaimercontent div.text p {
    margin-right: 5%;
  }

  .disclaimercontent div.text::-webkit-scrollbar {
    width: 8px;
  }

}


.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.close-button:hover {
  background-color: darkgray;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}


.hiddenscroll {
  overflow: hidden;
}
.pdp_herolakshachandi {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../../../../assets/pdp/pdpbanner/lakshachandi-heights-banner.webp"), lightgray 0% 0% / 100px 100px repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .hero_content {
        color: #fff;
        position: relative;
        text-align: center;
        max-width: 700px;
        padding: 0 1rem;

        
.detail {
    margin-bottom: 1.5rem;
    letter-spacing: 3.2px;
    text-transform: uppercase;
}
    }

    .pdp_decription {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        width: 290px;

        p {
            color: #fff;
            margin-bottom: 1rem;
        }
    }
}

// .new-hone{
//     font-family: "TFArrow Light", sans-serif;
//     font-size: 94px;
//     font-style: normal;
//     font-weight: 300;
//     line-height: normal;
//     text-transform: uppercase;
//     text-align: center;
//     line-height: 1.2;
//     letter-spacing: 8.9px;
//     display: flex;
//     flex-direction: column;
//     gap: 0;
// }

// span.new-hone-hthree{
//     font-family: Optima,sans-serif;
//     margin-bottom: 1.5rem;
//     letter-spacing: 3.2px;
//     font-size: 24px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 1.2;
//     text-transform: uppercase;
// }

// span.titlepara{
   
//         font-family: Optima, sans-serif;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 1.2;
// text-transform: none;
// letter-spacing: normal;
// }
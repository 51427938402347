.contact {
	width: 100%;
	height: 100%;
	background: url("../../../assets/contact-bgnew.webp");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media only screen and (max-width: 1200px) {
		height: 100%;
	}

	.title_container {
		color: #fff;
		text-align: center;
		padding: 4rem 0 4rem 0;
	}

	.input_container {
		width: 100%;
		background-color: #fff;
		padding: 70px 40px;

		@media only screen and (max-width: 1200px) {
			padding: 50px 0.5rem;
		}

		@media only screen and (max-width: 767px) {
			padding: 1.5rem;
		}

		.input_wrapper {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			// flex-wrap: wrap;

			.col-md-12.col-md-6.col-xs-12 {
				max-width: 16.6666666667%;
				width: 16.6666666667%;
				padding: 0rem 0.5rem;
			}

			.col-md-3.col-lg-2.col-xs-4 {
				max-width: 5%;
				width: 5%;
				padding-left: 0.5rem;

				@media only screen and (max-width: 1300px) {
					width: 10%;	
					max-width: 10%;
				}
			}

			.col-md-9.col-lg-10.col-xs-8.pad-l0 {
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}

			@media only screen and (max-width: 767px) {
				.col-md-3.col-lg-2.col-xs-4 {
					width: 20%;
					max-width: 20%;
					padding-left: 0;
				}

				.col-md-9.col-lg-10.col-xs-8 {
					width: 80%;
					max-width: 80%;
					padding-right: 0;
				}

			}

			.col-md-12 {
				width: 15%;
				max-width: 15%;

				@media only screen and (max-width: 767px) {
					width: 100%;
					max-width: 100%;

				}
			}

			@media only screen and (max-width: 767px) {
				flex-wrap: wrap;
			}

			div.col-md-12.col-md-6.col-xs-12 {


				@media only screen and (max-width: 767px) {
					max-width: 100%;
					width: 100%;
					padding: 0;
				}

			}


			.btn_primary {
				color: #000;
				border-color: #000;
				border: 1px solid;

				@media only screen and (max-width: 700px) {
					width: 100%;
				}
			}


			input,
			select {
				width: 100%;
				height: 42px;
				border: none;
				outline: none;
				border-bottom: 1px solid #000;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: #000;
				font-family: 'Optima Medium';
				margin-bottom: 1rem;	
				border-radius: 0;			
	
			}

			input::placeholder,
			select {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: #000;


				@media only screen and (max-width: 820px) {
					font-size: 12px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 16px;
				}
			}


		}


		.authorized {
			margin-top: 1rem;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				font-size: 11px;
				margin-left: 0.5rem;
			}
		}

	}

	.agree-txt {
		margin: 20px 0px;
	}

	#msg-success {
		font-size: 22px;
	}
}

.error_item {
	color: red;
	margin: 3px 1rem;
	font-size: 15px;

}

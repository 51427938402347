.info {
  width: 100%;
  background-image: url('../../../../assets/pdp/info.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5rem 0rem;

  .info_title {
    width: 45%;

    &>p {
      position: relative;
      margin-bottom: 1.5rem;
      font-size: 41px;
    }

    &>p:before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 1.2rem;
      transform: translateX(-50%);
      height: 2px;
      width: 40px;
      background-color: #005f9e;
    }

    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }

  hr {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 0.5rem;
  }

  .dropdown_container {
    margin-top: 3rem;

    .dropdown_item {
      .dropdown_title_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .dropdown_title {
          display: flex;
          align-items: center;
          padding: 1.1rem 0;
          gap: 1rem;

          .bullet {
            width: 20px;
            height: 20px;
            background-color: #d9d9d9;
          }
        }
      }
    }

    .info_content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      padding-left: 20px;

      li {
        margin-top: 0.5rem;
      }
    }

    .info_active {
      height: auto;
      max-height: 300px;
      transition: all 0.5s ease-in;
    }
  }
}